import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  address: '',
  chainId: '',
  isSignIn: false,
};

const slice = createSlice({
  name: 'metamask',
  initialState,
  reducers: {
    // Set Address
    setAddress(state, action) {
      state.address = action.payload;
    },
    // Set ChainId
    setChainId(state, action) {
      state.chainId = action.payload;
    },
    // Set IsSignIn
    setIsSignIn(state, action) {
      state.isSignIn = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setAddress, setChainId, setIsSignIn } = slice.actions;

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
