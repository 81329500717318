/**
 * @fileoverview gRPC-Web generated client stub for web3oj
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_web3oj_proto_notification_pb = require('../../../api/web3oj/proto/notification_pb.js')
const proto = {};
proto.web3oj = require('./notification_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.web3oj.NotificationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.web3oj.NotificationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.AllReadNotificationRequest,
 *   !proto.web3oj.AllReadNotificationResponse>}
 */
const methodDescriptor_NotificationService_AllReadNotification = new grpc.web.MethodDescriptor(
  '/web3oj.NotificationService/AllReadNotification',
  grpc.web.MethodType.UNARY,
  proto.web3oj.AllReadNotificationRequest,
  proto.web3oj.AllReadNotificationResponse,
  /**
   * @param {!proto.web3oj.AllReadNotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.AllReadNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.AllReadNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.AllReadNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.AllReadNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.NotificationServiceClient.prototype.allReadNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.NotificationService/AllReadNotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_AllReadNotification,
      callback);
};


/**
 * @param {!proto.web3oj.AllReadNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.AllReadNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.NotificationServicePromiseClient.prototype.allReadNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.NotificationService/AllReadNotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_AllReadNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.ReadNotificationRequest,
 *   !proto.web3oj.ReadNotificationResponse>}
 */
const methodDescriptor_NotificationService_ReadNotification = new grpc.web.MethodDescriptor(
  '/web3oj.NotificationService/ReadNotification',
  grpc.web.MethodType.UNARY,
  proto.web3oj.ReadNotificationRequest,
  proto.web3oj.ReadNotificationResponse,
  /**
   * @param {!proto.web3oj.ReadNotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.ReadNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.ReadNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.ReadNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.ReadNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.NotificationServiceClient.prototype.readNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.NotificationService/ReadNotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_ReadNotification,
      callback);
};


/**
 * @param {!proto.web3oj.ReadNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.ReadNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.NotificationServicePromiseClient.prototype.readNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.NotificationService/ReadNotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_ReadNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.SubscribeRequest,
 *   !proto.web3oj.SubscribeResponse>}
 */
const methodDescriptor_NotificationService_Subscribe = new grpc.web.MethodDescriptor(
  '/web3oj.NotificationService/Subscribe',
  grpc.web.MethodType.UNARY,
  proto.web3oj.SubscribeRequest,
  proto.web3oj.SubscribeResponse,
  /**
   * @param {!proto.web3oj.SubscribeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.SubscribeResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.SubscribeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.SubscribeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.SubscribeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.NotificationServiceClient.prototype.subscribe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.NotificationService/Subscribe',
      request,
      metadata || {},
      methodDescriptor_NotificationService_Subscribe,
      callback);
};


/**
 * @param {!proto.web3oj.SubscribeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.SubscribeResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.NotificationServicePromiseClient.prototype.subscribe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.NotificationService/Subscribe',
      request,
      metadata || {},
      methodDescriptor_NotificationService_Subscribe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.ListNotificationRequest,
 *   !proto.web3oj.ListNotificationResponse>}
 */
const methodDescriptor_NotificationService_ListNotification = new grpc.web.MethodDescriptor(
  '/web3oj.NotificationService/ListNotification',
  grpc.web.MethodType.UNARY,
  proto.web3oj.ListNotificationRequest,
  proto.web3oj.ListNotificationResponse,
  /**
   * @param {!proto.web3oj.ListNotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.ListNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.ListNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.ListNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.ListNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.NotificationServiceClient.prototype.listNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.NotificationService/ListNotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_ListNotification,
      callback);
};


/**
 * @param {!proto.web3oj.ListNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.ListNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.NotificationServicePromiseClient.prototype.listNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.NotificationService/ListNotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_ListNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.OnNotificationReceivedRequest,
 *   !proto.web3oj.OnNotificationReceivedResponse>}
 */
const methodDescriptor_NotificationService_OnNotificationReceived = new grpc.web.MethodDescriptor(
  '/web3oj.NotificationService/OnNotificationReceived',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.web3oj.OnNotificationReceivedRequest,
  proto.web3oj.OnNotificationReceivedResponse,
  /**
   * @param {!proto.web3oj.OnNotificationReceivedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.OnNotificationReceivedResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.OnNotificationReceivedRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.OnNotificationReceivedResponse>}
 *     The XHR Node Readable Stream
 */
proto.web3oj.NotificationServiceClient.prototype.onNotificationReceived =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/web3oj.NotificationService/OnNotificationReceived',
      request,
      metadata || {},
      methodDescriptor_NotificationService_OnNotificationReceived);
};


/**
 * @param {!proto.web3oj.OnNotificationReceivedRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.OnNotificationReceivedResponse>}
 *     The XHR Node Readable Stream
 */
proto.web3oj.NotificationServicePromiseClient.prototype.onNotificationReceived =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/web3oj.NotificationService/OnNotificationReceived',
      request,
      metadata || {},
      methodDescriptor_NotificationService_OnNotificationReceived);
};


module.exports = proto.web3oj;

