import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, Drawer, Avatar, Button, Stack, MenuItem } from '@mui/material';
import MenuPopover from '../../../components/MenuPopover';
import Logo from '../../../components/Logo';
import Image from '../../../components/Image';
import Scrollbar from '../../../components/Scrollbar';
// hooks
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import MyAvatar from '../../../components/MyAvatar';
import createAvatar from '../../../utils/createAvatar';
import { setAddress, setChainId, setIsSignIn } from '../../../redux/slices/metamask';
import { SignInRequest, GetUserRequest } from '../../../api/web3oj/proto/web3ojservice_pb';
import Web3OJClient from '../../../api/web3oj/cilent';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

const MetamaskConnectStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { translate } = useLocales();

  const navigate = useNavigate();
  const address = useSelector((state) => state.metamask.address);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { ethereum } = window;

  const [open, setOpen] = useState(null);
  const [blockchainId, setBlockchainId] = useState(null);

  const blockchainInfos = [
    {
      icon: '/assets/icons/ethereum_logo.svg',
      chainId: '0xaa36a7',
      name: 'Ethereum sepolia Testnet',
    },
  ];

  function isMobileDevice() {
    return 'ontouchstart' in window || 'onmsgesturechange' in window;
  }

  function getCurrentBlockchain(element) {
    return element.chainId === blockchainId;
  }

  const [currentBlockchainInfo, setCurrentBlockchainInfo] = useState(null);

  async function switchBlockchain(cid) {
    if (ethereum) {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: cid }],
      });
    }
  }

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang) => {
    handleClose();
  };

  const goToProfile = (address) => {
    navigate('/app/user/profile/'.concat(address));
  };

  const goToCreateUser = () => {
    navigate('/app/user/create');
  };

  function getUser(accountAddress) {
    const getUserRequest = new GetUserRequest();
    getUserRequest.setUserAddress(accountAddress);
    const metadata = { 'account-address': accountAddress };
    Web3OJClient.web3OJService.getUser(getUserRequest, metadata, (err, response) => {
      if (err != null) {
        alert(translate('UserCreate.message.notSignUpUser'));
        goToCreateUser();
      }
    });
  }

  function handleConnectChanged(connectInfo) {
    if (connectInfo.chainId.length !== 0) {
      dispatch(setChainId(connectInfo.chainId));
      setBlockchainId(connectInfo.chainId);
      setOpen(null);
    }
  }

  function hadleChainChanged(chainId) {
    if (chainId.length !== 0) {
      dispatch(setChainId(chainId));
      setBlockchainId(chainId);
      setOpen(null);
    }
  }

  function handleAccountsChanged(accounts) {
    if (accounts.length === 0) {
      console.log('Please connect to MetaMask.');
    } else if (accounts[0] !== address) {
      dispatch(setAddress(accounts[0]));
      sessionStorage.removeItem('AccessToken');
      sessionStorage.removeItem('AccessTokenExpiredAt');
      const cid = ethereum.chainId;
      if (cid !== null) {
        dispatch(setChainId(cid));
        setBlockchainId(cid);
      }
      signIn(accounts[0]);
    }
  }

  function handleSign(account, msg) {
    const signInRequest = new SignInRequest();
    signInRequest.setAccountAddress(account);
    signInRequest.setSignedMessage(msg);
    const metadata = { 'account-address': account };

    Web3OJClient.web3OJService.signIn(signInRequest, metadata, (err, response) => {
      if (err == null) {
        sessionStorage.setItem('Address', account);
        sessionStorage.setItem('AccessToken', response.getAccessToken());
        sessionStorage.setItem('AccessTokenExpiredAt', response.getExpiredAt());
        dispatch(setIsSignIn(true));
        getUser(account);
      }
    });
  }

  function connect() {
    ethereum
      .request({ method: 'eth_requestAccounts' })
      .then(handleAccountsChanged)
      .catch((err) => {
        if (err.code === 4001) {
          console.log('Please connect to MetaMask.');
        } else {
          console.error(err);
        }
      });
  }

  function signIn(from) {
    const signInMessge = 'Web3OJ Sign In';
    try {
      const msg = `0x${Buffer.from(signInMessge, 'utf8').toString('hex')}`;
      ethereum
        .request({
          method: 'personal_sign',
          params: [msg, from],
        })
        .then((msg) => handleSign(from, msg))
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.error(err);
    }
  }

  function checkSignIn() {
    const account = sessionStorage.getItem('Address');
    const accessToken = sessionStorage.getItem('AccessToken');
    const accessTokenExpiredAt = sessionStorage.getItem('AccessTokenExpiredAt');
    if (account != null && accessToken != null && accessTokenExpiredAt != null) {
      dispatch(setAddress(account));
      dispatch(setIsSignIn(true));
      const cid = ethereum.chainId;
      if (cid !== null) {
        dispatch(setChainId(cid));
        setBlockchainId(cid);
      }
    }
  }

  async function connectWallet() {
    if (ethereum && ethereum.isMetaMask) {
      ethereum.on('accountsChanged', handleAccountsChanged);
      ethereum.on('connect', handleConnectChanged);
      ethereum.on('chainChanged', hadleChainChanged);
      const chainId = await ethereum.request({ method: 'eth_chainId' });
      dispatch(setChainId(chainId));
      connect();
    } else {
      const downloadMetamaskUrl = 'https://metamask.io/download.html';
      alert('메타마스크가 설치되어 있지 않습니다.');
      console.log('Not Found Metamask');
      window.open(downloadMetamaskUrl, '_blank');
    }
  }

  async function mobileConnectWallet() {
    const metamaskAppDeepLink = 'https://metamask.app.link/dapp/app.web3oj.com/app/home';
    window.open(metamaskAppDeepLink);
    connect();
  }

  useEffect(() => {
    const blockchain = blockchainInfos.find(getCurrentBlockchain);
    setCurrentBlockchainInfo(blockchain);
    if (isMobileDevice() && ethereum) {
      connect();
    }
    checkSignIn();
  }, [pathname, blockchainId]);

  const renderMetamask = (
    <RootStyle
      onClick={() => {
        if (isMobileDevice()) {
          mobileConnectWallet();
        } else {
          connectWallet();
        }
      }}
    >
      <Avatar src="/assets/MetaMask.png" alt="photoURL" />
      <Box
        sx={{
          ml: 2,
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0,
          }),
        }}
      >
        <Typography variant="subtitle2" sx={{ color: 'text.primary' }} noWrap>
          {translate('ConnectMetamask')}
        </Typography>
      </Box>
    </RootStyle>
  );

  const renderProfile = (
    <RootStyle onClick={() => goToProfile(address)}>
      <MyAvatar />
      <Box
        sx={{
          ml: 2,
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0,
          }),
        }}
      >
        <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
          {address.substring(0, 15).concat('...')}
        </Typography>
      </Box>
    </RootStyle>
  );

  const renderBlockchainInfo = (
    <>
      <RootStyle onClick={handleOpen} sx={{ mb: 1 }}>
        {currentBlockchainInfo == null && (
          <Box
            sx={{
              ml: 2,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.shorter,
                }),
              ...(isCollapse && {
                ml: 0,
                width: 0,
              }),
            }}
          >
            <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
              {translate('UnsupportedBlockchain')}
            </Typography>
          </Box>
        )}
        {currentBlockchainInfo && (
          <>
            <Image src={currentBlockchainInfo.icon} sx={{ width: 23, height: 20, ml: 1 }} />
            <Box
              sx={{
                ml: 1,
                transition: (theme) =>
                  theme.transitions.create('width', {
                    duration: theme.transitions.duration.shorter,
                  }),
                ...(isCollapse && {
                  ml: 0,
                  width: 0,
                }),
              }}
            >
              <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                {currentBlockchainInfo.name}
              </Typography>
            </Box>
          </>
        )}
      </RootStyle>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {blockchainInfos.map((blockchainInfo) => (
            <MenuItem
              key={blockchainInfo.chainId}
              selected={ethereum && ethereum.chainId === blockchainInfo.chainId}
              onClick={() => switchBlockchain(blockchainInfo.chainId)}
            >
              <Image src={blockchainInfo.icon} sx={{ width: 23, height: 20, mr: 1 }} />
              {blockchainInfo.name}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );

  return (
    <div>
      {address === '' && renderMetamask}
      {address !== '' && (
        <>
          {renderBlockchainInfo}
          {renderProfile}
        </>
      )}
    </div>
  );
}
