/**
 * @fileoverview gRPC-Web generated client stub for web3oj
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_web3oj_proto_group_pb = require('../../../api/web3oj/proto/group_pb.js')
const proto = {};
proto.web3oj = require('./group_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.web3oj.GroupServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.web3oj.GroupServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.GetGroupRequest,
 *   !proto.web3oj.GetGroupResponse>}
 */
const methodDescriptor_GroupService_GetGroup = new grpc.web.MethodDescriptor(
  '/web3oj.GroupService/GetGroup',
  grpc.web.MethodType.UNARY,
  proto.web3oj.GetGroupRequest,
  proto.web3oj.GetGroupResponse,
  /**
   * @param {!proto.web3oj.GetGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.GetGroupResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.GetGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.GetGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.GetGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.GroupServiceClient.prototype.getGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.GroupService/GetGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_GetGroup,
      callback);
};


/**
 * @param {!proto.web3oj.GetGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.GetGroupResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.GroupServicePromiseClient.prototype.getGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.GroupService/GetGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_GetGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.ListGroupRequest,
 *   !proto.web3oj.ListGroupResponse>}
 */
const methodDescriptor_GroupService_ListGroup = new grpc.web.MethodDescriptor(
  '/web3oj.GroupService/ListGroup',
  grpc.web.MethodType.UNARY,
  proto.web3oj.ListGroupRequest,
  proto.web3oj.ListGroupResponse,
  /**
   * @param {!proto.web3oj.ListGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.ListGroupResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.ListGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.ListGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.ListGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.GroupServiceClient.prototype.listGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.GroupService/ListGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_ListGroup,
      callback);
};


/**
 * @param {!proto.web3oj.ListGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.ListGroupResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.GroupServicePromiseClient.prototype.listGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.GroupService/ListGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_ListGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.CreateGroupRequest,
 *   !proto.web3oj.CreateGroupResponse>}
 */
const methodDescriptor_GroupService_CreateGroup = new grpc.web.MethodDescriptor(
  '/web3oj.GroupService/CreateGroup',
  grpc.web.MethodType.UNARY,
  proto.web3oj.CreateGroupRequest,
  proto.web3oj.CreateGroupResponse,
  /**
   * @param {!proto.web3oj.CreateGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.CreateGroupResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.CreateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.CreateGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.CreateGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.GroupServiceClient.prototype.createGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.GroupService/CreateGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_CreateGroup,
      callback);
};


/**
 * @param {!proto.web3oj.CreateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.CreateGroupResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.GroupServicePromiseClient.prototype.createGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.GroupService/CreateGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_CreateGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.CreateUserGroupRequest,
 *   !proto.web3oj.CreateUserGroupResponse>}
 */
const methodDescriptor_GroupService_CreateUserGroup = new grpc.web.MethodDescriptor(
  '/web3oj.GroupService/CreateUserGroup',
  grpc.web.MethodType.UNARY,
  proto.web3oj.CreateUserGroupRequest,
  proto.web3oj.CreateUserGroupResponse,
  /**
   * @param {!proto.web3oj.CreateUserGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.CreateUserGroupResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.CreateUserGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.CreateUserGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.CreateUserGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.GroupServiceClient.prototype.createUserGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.GroupService/CreateUserGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_CreateUserGroup,
      callback);
};


/**
 * @param {!proto.web3oj.CreateUserGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.CreateUserGroupResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.GroupServicePromiseClient.prototype.createUserGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.GroupService/CreateUserGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_CreateUserGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.UpdateUserGroupRequest,
 *   !proto.web3oj.UpdateUserGroupResponse>}
 */
const methodDescriptor_GroupService_UpdateUserGroup = new grpc.web.MethodDescriptor(
  '/web3oj.GroupService/UpdateUserGroup',
  grpc.web.MethodType.UNARY,
  proto.web3oj.UpdateUserGroupRequest,
  proto.web3oj.UpdateUserGroupResponse,
  /**
   * @param {!proto.web3oj.UpdateUserGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.UpdateUserGroupResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.UpdateUserGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.UpdateUserGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.UpdateUserGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.GroupServiceClient.prototype.updateUserGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.GroupService/UpdateUserGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_UpdateUserGroup,
      callback);
};


/**
 * @param {!proto.web3oj.UpdateUserGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.UpdateUserGroupResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.GroupServicePromiseClient.prototype.updateUserGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.GroupService/UpdateUserGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_UpdateUserGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.UpdateGroupRequest,
 *   !proto.web3oj.UpdateGroupResponse>}
 */
const methodDescriptor_GroupService_UpdateGroup = new grpc.web.MethodDescriptor(
  '/web3oj.GroupService/UpdateGroup',
  grpc.web.MethodType.UNARY,
  proto.web3oj.UpdateGroupRequest,
  proto.web3oj.UpdateGroupResponse,
  /**
   * @param {!proto.web3oj.UpdateGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.UpdateGroupResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.UpdateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.UpdateGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.UpdateGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.GroupServiceClient.prototype.updateGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.GroupService/UpdateGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_UpdateGroup,
      callback);
};


/**
 * @param {!proto.web3oj.UpdateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.UpdateGroupResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.GroupServicePromiseClient.prototype.updateGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.GroupService/UpdateGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_UpdateGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.DeleteGroupRequest,
 *   !proto.web3oj.DeleteGroupResponse>}
 */
const methodDescriptor_GroupService_DeleteGroup = new grpc.web.MethodDescriptor(
  '/web3oj.GroupService/DeleteGroup',
  grpc.web.MethodType.UNARY,
  proto.web3oj.DeleteGroupRequest,
  proto.web3oj.DeleteGroupResponse,
  /**
   * @param {!proto.web3oj.DeleteGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.DeleteGroupResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.DeleteGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.DeleteGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.DeleteGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.GroupServiceClient.prototype.deleteGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.GroupService/DeleteGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_DeleteGroup,
      callback);
};


/**
 * @param {!proto.web3oj.DeleteGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.DeleteGroupResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.GroupServicePromiseClient.prototype.deleteGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.GroupService/DeleteGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_DeleteGroup);
};


module.exports = proto.web3oj;

