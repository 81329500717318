/**
 * @fileoverview gRPC-Web generated client stub for web3oj
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var api_web3oj_proto_problem_pb = require('../../../api/web3oj/proto/problem_pb.js')

var api_web3oj_proto_user_pb = require('../../../api/web3oj/proto/user_pb.js')

var api_web3oj_proto_board_pb = require('../../../api/web3oj/proto/board_pb.js')
const proto = {};
proto.web3oj = require('./web3ojservice_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.web3oj.Web3OJServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.web3oj.Web3OJServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.SignInRequest,
 *   !proto.web3oj.SignInResponse>}
 */
const methodDescriptor_Web3OJService_SignIn = new grpc.web.MethodDescriptor(
  '/web3oj.Web3OJService/SignIn',
  grpc.web.MethodType.UNARY,
  proto.web3oj.SignInRequest,
  proto.web3oj.SignInResponse,
  /**
   * @param {!proto.web3oj.SignInRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.SignInResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.SignInRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.SignInResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.SignInResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.Web3OJServiceClient.prototype.signIn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.Web3OJService/SignIn',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_SignIn,
      callback);
};


/**
 * @param {!proto.web3oj.SignInRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.SignInResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.Web3OJServicePromiseClient.prototype.signIn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.Web3OJService/SignIn',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_SignIn);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.SignUpRequest,
 *   !proto.web3oj.SignUpResponse>}
 */
const methodDescriptor_Web3OJService_SignUp = new grpc.web.MethodDescriptor(
  '/web3oj.Web3OJService/SignUp',
  grpc.web.MethodType.UNARY,
  proto.web3oj.SignUpRequest,
  proto.web3oj.SignUpResponse,
  /**
   * @param {!proto.web3oj.SignUpRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.SignUpResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.SignUpRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.SignUpResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.SignUpResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.Web3OJServiceClient.prototype.signUp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.Web3OJService/SignUp',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_SignUp,
      callback);
};


/**
 * @param {!proto.web3oj.SignUpRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.SignUpResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.Web3OJServicePromiseClient.prototype.signUp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.Web3OJService/SignUp',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_SignUp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.UpdateProfileRequest,
 *   !proto.web3oj.UpdateProfileResponse>}
 */
const methodDescriptor_Web3OJService_UpdateProfile = new grpc.web.MethodDescriptor(
  '/web3oj.Web3OJService/UpdateProfile',
  grpc.web.MethodType.UNARY,
  proto.web3oj.UpdateProfileRequest,
  proto.web3oj.UpdateProfileResponse,
  /**
   * @param {!proto.web3oj.UpdateProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.UpdateProfileResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.UpdateProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.UpdateProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.UpdateProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.Web3OJServiceClient.prototype.updateProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.Web3OJService/UpdateProfile',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_UpdateProfile,
      callback);
};


/**
 * @param {!proto.web3oj.UpdateProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.UpdateProfileResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.Web3OJServicePromiseClient.prototype.updateProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.Web3OJService/UpdateProfile',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_UpdateProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.GetDashboardInfoRequest,
 *   !proto.web3oj.GetDashboardInfoResponse>}
 */
const methodDescriptor_Web3OJService_GetDashboardInfo = new grpc.web.MethodDescriptor(
  '/web3oj.Web3OJService/GetDashboardInfo',
  grpc.web.MethodType.UNARY,
  proto.web3oj.GetDashboardInfoRequest,
  proto.web3oj.GetDashboardInfoResponse,
  /**
   * @param {!proto.web3oj.GetDashboardInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.GetDashboardInfoResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.GetDashboardInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.GetDashboardInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.GetDashboardInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.Web3OJServiceClient.prototype.getDashboardInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.Web3OJService/GetDashboardInfo',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_GetDashboardInfo,
      callback);
};


/**
 * @param {!proto.web3oj.GetDashboardInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.GetDashboardInfoResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.Web3OJServicePromiseClient.prototype.getDashboardInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.Web3OJService/GetDashboardInfo',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_GetDashboardInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.GetProblemsRequest,
 *   !proto.web3oj.GetProblemsResponse>}
 */
const methodDescriptor_Web3OJService_GetProblems = new grpc.web.MethodDescriptor(
  '/web3oj.Web3OJService/GetProblems',
  grpc.web.MethodType.UNARY,
  proto.web3oj.GetProblemsRequest,
  proto.web3oj.GetProblemsResponse,
  /**
   * @param {!proto.web3oj.GetProblemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.GetProblemsResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.GetProblemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.GetProblemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.GetProblemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.Web3OJServiceClient.prototype.getProblems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.Web3OJService/GetProblems',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_GetProblems,
      callback);
};


/**
 * @param {!proto.web3oj.GetProblemsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.GetProblemsResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.Web3OJServicePromiseClient.prototype.getProblems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.Web3OJService/GetProblems',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_GetProblems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.GetProblemRequest,
 *   !proto.web3oj.GetProblemResponse>}
 */
const methodDescriptor_Web3OJService_GetProblem = new grpc.web.MethodDescriptor(
  '/web3oj.Web3OJService/GetProblem',
  grpc.web.MethodType.UNARY,
  proto.web3oj.GetProblemRequest,
  proto.web3oj.GetProblemResponse,
  /**
   * @param {!proto.web3oj.GetProblemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.GetProblemResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.GetProblemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.GetProblemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.GetProblemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.Web3OJServiceClient.prototype.getProblem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.Web3OJService/GetProblem',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_GetProblem,
      callback);
};


/**
 * @param {!proto.web3oj.GetProblemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.GetProblemResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.Web3OJServicePromiseClient.prototype.getProblem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.Web3OJService/GetProblem',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_GetProblem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.GetRankRequest,
 *   !proto.web3oj.GetRankResponse>}
 */
const methodDescriptor_Web3OJService_GetRank = new grpc.web.MethodDescriptor(
  '/web3oj.Web3OJService/GetRank',
  grpc.web.MethodType.UNARY,
  proto.web3oj.GetRankRequest,
  proto.web3oj.GetRankResponse,
  /**
   * @param {!proto.web3oj.GetRankRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.GetRankResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.GetRankRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.GetRankResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.GetRankResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.Web3OJServiceClient.prototype.getRank =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.Web3OJService/GetRank',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_GetRank,
      callback);
};


/**
 * @param {!proto.web3oj.GetRankRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.GetRankResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.Web3OJServicePromiseClient.prototype.getRank =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.Web3OJService/GetRank',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_GetRank);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.GetUserRequest,
 *   !proto.web3oj.GetUserResponse>}
 */
const methodDescriptor_Web3OJService_GetUser = new grpc.web.MethodDescriptor(
  '/web3oj.Web3OJService/GetUser',
  grpc.web.MethodType.UNARY,
  proto.web3oj.GetUserRequest,
  proto.web3oj.GetUserResponse,
  /**
   * @param {!proto.web3oj.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.GetUserResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.GetUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.GetUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.Web3OJServiceClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.Web3OJService/GetUser',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_GetUser,
      callback);
};


/**
 * @param {!proto.web3oj.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.GetUserResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.Web3OJServicePromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.Web3OJService/GetUser',
      request,
      metadata || {},
      methodDescriptor_Web3OJService_GetUser);
};


module.exports = proto.web3oj;

