// source: api/web3oj/proto/competition_problem_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var api_web3oj_proto_competition_problem_pb = require('../../../api/web3oj/proto/competition_problem_pb.js');
goog.object.extend(proto, api_web3oj_proto_competition_problem_pb);
goog.exportSymbol('proto.web3oj.GetCompetitionProblemRankRequest', null, global);
goog.exportSymbol('proto.web3oj.GetCompetitionProblemRankResponse', null, global);
goog.exportSymbol('proto.web3oj.GetCompetitionProblemRequest', null, global);
goog.exportSymbol('proto.web3oj.GetCompetitionProblemResponse', null, global);
goog.exportSymbol('proto.web3oj.ListCompetitionProblemRequest', null, global);
goog.exportSymbol('proto.web3oj.ListCompetitionProblemResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.ListCompetitionProblemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.ListCompetitionProblemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.ListCompetitionProblemRequest.displayName = 'proto.web3oj.ListCompetitionProblemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.ListCompetitionProblemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web3oj.ListCompetitionProblemResponse.repeatedFields_, null);
};
goog.inherits(proto.web3oj.ListCompetitionProblemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.ListCompetitionProblemResponse.displayName = 'proto.web3oj.ListCompetitionProblemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetCompetitionProblemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.GetCompetitionProblemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetCompetitionProblemRequest.displayName = 'proto.web3oj.GetCompetitionProblemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetCompetitionProblemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.GetCompetitionProblemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetCompetitionProblemResponse.displayName = 'proto.web3oj.GetCompetitionProblemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetCompetitionProblemRankRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.GetCompetitionProblemRankRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetCompetitionProblemRankRequest.displayName = 'proto.web3oj.GetCompetitionProblemRankRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetCompetitionProblemRankResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.GetCompetitionProblemRankResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetCompetitionProblemRankResponse.displayName = 'proto.web3oj.GetCompetitionProblemRankResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.ListCompetitionProblemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.ListCompetitionProblemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.ListCompetitionProblemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.ListCompetitionProblemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.ListCompetitionProblemRequest}
 */
proto.web3oj.ListCompetitionProblemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.ListCompetitionProblemRequest;
  return proto.web3oj.ListCompetitionProblemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.ListCompetitionProblemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.ListCompetitionProblemRequest}
 */
proto.web3oj.ListCompetitionProblemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.ListCompetitionProblemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.ListCompetitionProblemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.ListCompetitionProblemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.ListCompetitionProblemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.web3oj.ListCompetitionProblemRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.ListCompetitionProblemRequest} returns this
 */
proto.web3oj.ListCompetitionProblemRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 size = 2;
 * @return {number}
 */
proto.web3oj.ListCompetitionProblemRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.ListCompetitionProblemRequest} returns this
 */
proto.web3oj.ListCompetitionProblemRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web3oj.ListCompetitionProblemResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.ListCompetitionProblemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.ListCompetitionProblemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.ListCompetitionProblemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.ListCompetitionProblemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    competitionProblemsList: jspb.Message.toObjectList(msg.getCompetitionProblemsList(),
    api_web3oj_proto_competition_problem_pb.CompetitionProblem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.ListCompetitionProblemResponse}
 */
proto.web3oj.ListCompetitionProblemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.ListCompetitionProblemResponse;
  return proto.web3oj.ListCompetitionProblemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.ListCompetitionProblemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.ListCompetitionProblemResponse}
 */
proto.web3oj.ListCompetitionProblemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new api_web3oj_proto_competition_problem_pb.CompetitionProblem;
      reader.readMessage(value,api_web3oj_proto_competition_problem_pb.CompetitionProblem.deserializeBinaryFromReader);
      msg.addCompetitionProblems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.ListCompetitionProblemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.ListCompetitionProblemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.ListCompetitionProblemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.ListCompetitionProblemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompetitionProblemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      api_web3oj_proto_competition_problem_pb.CompetitionProblem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_count = 1;
 * @return {number}
 */
proto.web3oj.ListCompetitionProblemResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.ListCompetitionProblemResponse} returns this
 */
proto.web3oj.ListCompetitionProblemResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated CompetitionProblem competition_problems = 2;
 * @return {!Array<!proto.web3oj.CompetitionProblem>}
 */
proto.web3oj.ListCompetitionProblemResponse.prototype.getCompetitionProblemsList = function() {
  return /** @type{!Array<!proto.web3oj.CompetitionProblem>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_web3oj_proto_competition_problem_pb.CompetitionProblem, 2));
};


/**
 * @param {!Array<!proto.web3oj.CompetitionProblem>} value
 * @return {!proto.web3oj.ListCompetitionProblemResponse} returns this
*/
proto.web3oj.ListCompetitionProblemResponse.prototype.setCompetitionProblemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.web3oj.CompetitionProblem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.CompetitionProblem}
 */
proto.web3oj.ListCompetitionProblemResponse.prototype.addCompetitionProblems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.web3oj.CompetitionProblem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.ListCompetitionProblemResponse} returns this
 */
proto.web3oj.ListCompetitionProblemResponse.prototype.clearCompetitionProblemsList = function() {
  return this.setCompetitionProblemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetCompetitionProblemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetCompetitionProblemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetCompetitionProblemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetCompetitionProblemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    competitionProblemId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetCompetitionProblemRequest}
 */
proto.web3oj.GetCompetitionProblemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetCompetitionProblemRequest;
  return proto.web3oj.GetCompetitionProblemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetCompetitionProblemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetCompetitionProblemRequest}
 */
proto.web3oj.GetCompetitionProblemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChainId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionProblemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetCompetitionProblemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetCompetitionProblemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetCompetitionProblemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetCompetitionProblemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompetitionProblemId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string chain_id = 1;
 * @return {string}
 */
proto.web3oj.GetCompetitionProblemRequest.prototype.getChainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.GetCompetitionProblemRequest} returns this
 */
proto.web3oj.GetCompetitionProblemRequest.prototype.setChainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 competition_problem_id = 2;
 * @return {number}
 */
proto.web3oj.GetCompetitionProblemRequest.prototype.getCompetitionProblemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetCompetitionProblemRequest} returns this
 */
proto.web3oj.GetCompetitionProblemRequest.prototype.setCompetitionProblemId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetCompetitionProblemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetCompetitionProblemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetCompetitionProblemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetCompetitionProblemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionProblem: (f = msg.getCompetitionProblem()) && api_web3oj_proto_competition_problem_pb.CompetitionProblem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetCompetitionProblemResponse}
 */
proto.web3oj.GetCompetitionProblemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetCompetitionProblemResponse;
  return proto.web3oj.GetCompetitionProblemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetCompetitionProblemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetCompetitionProblemResponse}
 */
proto.web3oj.GetCompetitionProblemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_web3oj_proto_competition_problem_pb.CompetitionProblem;
      reader.readMessage(value,api_web3oj_proto_competition_problem_pb.CompetitionProblem.deserializeBinaryFromReader);
      msg.setCompetitionProblem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetCompetitionProblemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetCompetitionProblemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetCompetitionProblemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetCompetitionProblemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionProblem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_web3oj_proto_competition_problem_pb.CompetitionProblem.serializeBinaryToWriter
    );
  }
};


/**
 * optional CompetitionProblem competition_problem = 1;
 * @return {?proto.web3oj.CompetitionProblem}
 */
proto.web3oj.GetCompetitionProblemResponse.prototype.getCompetitionProblem = function() {
  return /** @type{?proto.web3oj.CompetitionProblem} */ (
    jspb.Message.getWrapperField(this, api_web3oj_proto_competition_problem_pb.CompetitionProblem, 1));
};


/**
 * @param {?proto.web3oj.CompetitionProblem|undefined} value
 * @return {!proto.web3oj.GetCompetitionProblemResponse} returns this
*/
proto.web3oj.GetCompetitionProblemResponse.prototype.setCompetitionProblem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web3oj.GetCompetitionProblemResponse} returns this
 */
proto.web3oj.GetCompetitionProblemResponse.prototype.clearCompetitionProblem = function() {
  return this.setCompetitionProblem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web3oj.GetCompetitionProblemResponse.prototype.hasCompetitionProblem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetCompetitionProblemRankRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetCompetitionProblemRankRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetCompetitionProblemRankRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetCompetitionProblemRankRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionProblemId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    size: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetCompetitionProblemRankRequest}
 */
proto.web3oj.GetCompetitionProblemRankRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetCompetitionProblemRankRequest;
  return proto.web3oj.GetCompetitionProblemRankRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetCompetitionProblemRankRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetCompetitionProblemRankRequest}
 */
proto.web3oj.GetCompetitionProblemRankRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionProblemId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetCompetitionProblemRankRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetCompetitionProblemRankRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetCompetitionProblemRankRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetCompetitionProblemRankRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionProblemId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 competition_problem_id = 1;
 * @return {number}
 */
proto.web3oj.GetCompetitionProblemRankRequest.prototype.getCompetitionProblemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetCompetitionProblemRankRequest} returns this
 */
proto.web3oj.GetCompetitionProblemRankRequest.prototype.setCompetitionProblemId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.web3oj.GetCompetitionProblemRankRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetCompetitionProblemRankRequest} returns this
 */
proto.web3oj.GetCompetitionProblemRankRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 size = 3;
 * @return {number}
 */
proto.web3oj.GetCompetitionProblemRankRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetCompetitionProblemRankRequest} returns this
 */
proto.web3oj.GetCompetitionProblemRankRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetCompetitionProblemRankResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetCompetitionProblemRankResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetCompetitionProblemRankResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetCompetitionProblemRankResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    competitionProblemRank: (f = msg.getCompetitionProblemRank()) && api_web3oj_proto_competition_problem_pb.CompetitionProblemRank.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetCompetitionProblemRankResponse}
 */
proto.web3oj.GetCompetitionProblemRankResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetCompetitionProblemRankResponse;
  return proto.web3oj.GetCompetitionProblemRankResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetCompetitionProblemRankResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetCompetitionProblemRankResponse}
 */
proto.web3oj.GetCompetitionProblemRankResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new api_web3oj_proto_competition_problem_pb.CompetitionProblemRank;
      reader.readMessage(value,api_web3oj_proto_competition_problem_pb.CompetitionProblemRank.deserializeBinaryFromReader);
      msg.setCompetitionProblemRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetCompetitionProblemRankResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetCompetitionProblemRankResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetCompetitionProblemRankResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetCompetitionProblemRankResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompetitionProblemRank();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_web3oj_proto_competition_problem_pb.CompetitionProblemRank.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_count = 1;
 * @return {number}
 */
proto.web3oj.GetCompetitionProblemRankResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetCompetitionProblemRankResponse} returns this
 */
proto.web3oj.GetCompetitionProblemRankResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CompetitionProblemRank competition_problem_rank = 2;
 * @return {?proto.web3oj.CompetitionProblemRank}
 */
proto.web3oj.GetCompetitionProblemRankResponse.prototype.getCompetitionProblemRank = function() {
  return /** @type{?proto.web3oj.CompetitionProblemRank} */ (
    jspb.Message.getWrapperField(this, api_web3oj_proto_competition_problem_pb.CompetitionProblemRank, 2));
};


/**
 * @param {?proto.web3oj.CompetitionProblemRank|undefined} value
 * @return {!proto.web3oj.GetCompetitionProblemRankResponse} returns this
*/
proto.web3oj.GetCompetitionProblemRankResponse.prototype.setCompetitionProblemRank = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web3oj.GetCompetitionProblemRankResponse} returns this
 */
proto.web3oj.GetCompetitionProblemRankResponse.prototype.clearCompetitionProblemRank = function() {
  return this.setCompetitionProblemRank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web3oj.GetCompetitionProblemRankResponse.prototype.hasCompetitionProblemRank = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.web3oj);
