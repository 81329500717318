// source: api/web3oj/proto/web3ojservice.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var api_web3oj_proto_problem_pb = require('../../../api/web3oj/proto/problem_pb.js');
goog.object.extend(proto, api_web3oj_proto_problem_pb);
var api_web3oj_proto_user_pb = require('../../../api/web3oj/proto/user_pb.js');
goog.object.extend(proto, api_web3oj_proto_user_pb);
var api_web3oj_proto_board_pb = require('../../../api/web3oj/proto/board_pb.js');
goog.object.extend(proto, api_web3oj_proto_board_pb);
goog.exportSymbol('proto.web3oj.GetDashboardInfoRequest', null, global);
goog.exportSymbol('proto.web3oj.GetDashboardInfoResponse', null, global);
goog.exportSymbol('proto.web3oj.GetProblemRequest', null, global);
goog.exportSymbol('proto.web3oj.GetProblemResponse', null, global);
goog.exportSymbol('proto.web3oj.GetProblemsRequest', null, global);
goog.exportSymbol('proto.web3oj.GetProblemsRequest.Filter', null, global);
goog.exportSymbol('proto.web3oj.GetProblemsRequest.Filter.DifficultyFilter', null, global);
goog.exportSymbol('proto.web3oj.GetProblemsRequest.Filter.StatusFilter', null, global);
goog.exportSymbol('proto.web3oj.GetProblemsRequest.OrderBy', null, global);
goog.exportSymbol('proto.web3oj.GetProblemsResponse', null, global);
goog.exportSymbol('proto.web3oj.GetRankRequest', null, global);
goog.exportSymbol('proto.web3oj.GetRankResponse', null, global);
goog.exportSymbol('proto.web3oj.GetUserRequest', null, global);
goog.exportSymbol('proto.web3oj.GetUserResponse', null, global);
goog.exportSymbol('proto.web3oj.SignInRequest', null, global);
goog.exportSymbol('proto.web3oj.SignInResponse', null, global);
goog.exportSymbol('proto.web3oj.SignInResponse.State', null, global);
goog.exportSymbol('proto.web3oj.SignUpRequest', null, global);
goog.exportSymbol('proto.web3oj.SignUpResponse', null, global);
goog.exportSymbol('proto.web3oj.UpdateProfileRequest', null, global);
goog.exportSymbol('proto.web3oj.UpdateProfileResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetDashboardInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.GetDashboardInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetDashboardInfoRequest.displayName = 'proto.web3oj.GetDashboardInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetDashboardInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web3oj.GetDashboardInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.web3oj.GetDashboardInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetDashboardInfoResponse.displayName = 'proto.web3oj.GetDashboardInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetProblemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.GetProblemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetProblemsRequest.displayName = 'proto.web3oj.GetProblemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetProblemsRequest.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web3oj.GetProblemsRequest.Filter.repeatedFields_, null);
};
goog.inherits(proto.web3oj.GetProblemsRequest.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetProblemsRequest.Filter.displayName = 'proto.web3oj.GetProblemsRequest.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetProblemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web3oj.GetProblemsResponse.repeatedFields_, null);
};
goog.inherits(proto.web3oj.GetProblemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetProblemsResponse.displayName = 'proto.web3oj.GetProblemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetProblemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.GetProblemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetProblemRequest.displayName = 'proto.web3oj.GetProblemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetProblemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.GetProblemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetProblemResponse.displayName = 'proto.web3oj.GetProblemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetRankRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.GetRankRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetRankRequest.displayName = 'proto.web3oj.GetRankRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetRankResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web3oj.GetRankResponse.repeatedFields_, null);
};
goog.inherits(proto.web3oj.GetRankResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetRankResponse.displayName = 'proto.web3oj.GetRankResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.GetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetUserRequest.displayName = 'proto.web3oj.GetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.GetUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web3oj.GetUserResponse.repeatedFields_, null);
};
goog.inherits(proto.web3oj.GetUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.GetUserResponse.displayName = 'proto.web3oj.GetUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.SignInRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.SignInRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.SignInRequest.displayName = 'proto.web3oj.SignInRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.SignInResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.SignInResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.SignInResponse.displayName = 'proto.web3oj.SignInResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.SignUpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.SignUpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.SignUpRequest.displayName = 'proto.web3oj.SignUpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.SignUpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.SignUpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.SignUpResponse.displayName = 'proto.web3oj.SignUpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.UpdateProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.UpdateProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.UpdateProfileRequest.displayName = 'proto.web3oj.UpdateProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.UpdateProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.UpdateProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.UpdateProfileResponse.displayName = 'proto.web3oj.UpdateProfileResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetDashboardInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetDashboardInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetDashboardInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetDashboardInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetDashboardInfoRequest}
 */
proto.web3oj.GetDashboardInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetDashboardInfoRequest;
  return proto.web3oj.GetDashboardInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetDashboardInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetDashboardInfoRequest}
 */
proto.web3oj.GetDashboardInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetDashboardInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetDashboardInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetDashboardInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetDashboardInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web3oj.GetDashboardInfoResponse.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetDashboardInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetDashboardInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetDashboardInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetDashboardInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    numOfProblem: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numOfUser: jspb.Message.getFieldWithDefault(msg, 2, 0),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    api_web3oj_proto_user_pb.User.toObject, includeInstance),
    postsList: jspb.Message.toObjectList(msg.getPostsList(),
    api_web3oj_proto_board_pb.Post.toObject, includeInstance),
    numOfCompetitionProblem: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetDashboardInfoResponse}
 */
proto.web3oj.GetDashboardInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetDashboardInfoResponse;
  return proto.web3oj.GetDashboardInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetDashboardInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetDashboardInfoResponse}
 */
proto.web3oj.GetDashboardInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOfProblem(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOfUser(value);
      break;
    case 3:
      var value = new api_web3oj_proto_user_pb.User;
      reader.readMessage(value,api_web3oj_proto_user_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 4:
      var value = new api_web3oj_proto_board_pb.Post;
      reader.readMessage(value,api_web3oj_proto_board_pb.Post.deserializeBinaryFromReader);
      msg.addPosts(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOfCompetitionProblem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetDashboardInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetDashboardInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetDashboardInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetDashboardInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumOfProblem();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNumOfUser();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      api_web3oj_proto_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getPostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      api_web3oj_proto_board_pb.Post.serializeBinaryToWriter
    );
  }
  f = message.getNumOfCompetitionProblem();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 num_of_problem = 1;
 * @return {number}
 */
proto.web3oj.GetDashboardInfoResponse.prototype.getNumOfProblem = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetDashboardInfoResponse} returns this
 */
proto.web3oj.GetDashboardInfoResponse.prototype.setNumOfProblem = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 num_of_user = 2;
 * @return {number}
 */
proto.web3oj.GetDashboardInfoResponse.prototype.getNumOfUser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetDashboardInfoResponse} returns this
 */
proto.web3oj.GetDashboardInfoResponse.prototype.setNumOfUser = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated User users = 3;
 * @return {!Array<!proto.web3oj.User>}
 */
proto.web3oj.GetDashboardInfoResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.web3oj.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_web3oj_proto_user_pb.User, 3));
};


/**
 * @param {!Array<!proto.web3oj.User>} value
 * @return {!proto.web3oj.GetDashboardInfoResponse} returns this
*/
proto.web3oj.GetDashboardInfoResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.web3oj.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.User}
 */
proto.web3oj.GetDashboardInfoResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.web3oj.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.GetDashboardInfoResponse} returns this
 */
proto.web3oj.GetDashboardInfoResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * repeated Post posts = 4;
 * @return {!Array<!proto.web3oj.Post>}
 */
proto.web3oj.GetDashboardInfoResponse.prototype.getPostsList = function() {
  return /** @type{!Array<!proto.web3oj.Post>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_web3oj_proto_board_pb.Post, 4));
};


/**
 * @param {!Array<!proto.web3oj.Post>} value
 * @return {!proto.web3oj.GetDashboardInfoResponse} returns this
*/
proto.web3oj.GetDashboardInfoResponse.prototype.setPostsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.web3oj.Post=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.Post}
 */
proto.web3oj.GetDashboardInfoResponse.prototype.addPosts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.web3oj.Post, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.GetDashboardInfoResponse} returns this
 */
proto.web3oj.GetDashboardInfoResponse.prototype.clearPostsList = function() {
  return this.setPostsList([]);
};


/**
 * optional int32 num_of_competition_problem = 5;
 * @return {number}
 */
proto.web3oj.GetDashboardInfoResponse.prototype.getNumOfCompetitionProblem = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetDashboardInfoResponse} returns this
 */
proto.web3oj.GetDashboardInfoResponse.prototype.setNumOfCompetitionProblem = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetProblemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetProblemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetProblemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetProblemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0),
    problemTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orderBy: jspb.Message.getFieldWithDefault(msg, 4, 0),
    filter: (f = msg.getFilter()) && proto.web3oj.GetProblemsRequest.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetProblemsRequest}
 */
proto.web3oj.GetProblemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetProblemsRequest;
  return proto.web3oj.GetProblemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetProblemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetProblemsRequest}
 */
proto.web3oj.GetProblemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProblemTitle(value);
      break;
    case 4:
      var value = /** @type {!proto.web3oj.GetProblemsRequest.OrderBy} */ (reader.readEnum());
      msg.setOrderBy(value);
      break;
    case 5:
      var value = new proto.web3oj.GetProblemsRequest.Filter;
      reader.readMessage(value,proto.web3oj.GetProblemsRequest.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetProblemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetProblemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetProblemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetProblemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getProblemTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrderBy();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.web3oj.GetProblemsRequest.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.web3oj.GetProblemsRequest.OrderBy = {
  CREATED_AT_ASC: 0,
  CREATED_AT_DESC: 1
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web3oj.GetProblemsRequest.Filter.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetProblemsRequest.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetProblemsRequest.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetProblemsRequest.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetProblemsRequest.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    difficultyFilterList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    statusFilterList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetProblemsRequest.Filter}
 */
proto.web3oj.GetProblemsRequest.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetProblemsRequest.Filter;
  return proto.web3oj.GetProblemsRequest.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetProblemsRequest.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetProblemsRequest.Filter}
 */
proto.web3oj.GetProblemsRequest.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.web3oj.GetProblemsRequest.Filter.DifficultyFilter>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDifficultyFilter(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<!proto.web3oj.GetProblemsRequest.Filter.StatusFilter>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatusFilter(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetProblemsRequest.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetProblemsRequest.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetProblemsRequest.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetProblemsRequest.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDifficultyFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getStatusFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.web3oj.GetProblemsRequest.Filter.DifficultyFilter = {
  EASY: 0,
  MEDIUM: 1,
  HARD: 2
};

/**
 * @enum {number}
 */
proto.web3oj.GetProblemsRequest.Filter.StatusFilter = {
  PASS: 0,
  ING: 1,
  NOT_YET: 2
};

/**
 * repeated DifficultyFilter difficulty_filter = 1;
 * @return {!Array<!proto.web3oj.GetProblemsRequest.Filter.DifficultyFilter>}
 */
proto.web3oj.GetProblemsRequest.Filter.prototype.getDifficultyFilterList = function() {
  return /** @type {!Array<!proto.web3oj.GetProblemsRequest.Filter.DifficultyFilter>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.web3oj.GetProblemsRequest.Filter.DifficultyFilter>} value
 * @return {!proto.web3oj.GetProblemsRequest.Filter} returns this
 */
proto.web3oj.GetProblemsRequest.Filter.prototype.setDifficultyFilterList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.web3oj.GetProblemsRequest.Filter.DifficultyFilter} value
 * @param {number=} opt_index
 * @return {!proto.web3oj.GetProblemsRequest.Filter} returns this
 */
proto.web3oj.GetProblemsRequest.Filter.prototype.addDifficultyFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.GetProblemsRequest.Filter} returns this
 */
proto.web3oj.GetProblemsRequest.Filter.prototype.clearDifficultyFilterList = function() {
  return this.setDifficultyFilterList([]);
};


/**
 * repeated StatusFilter status_filter = 2;
 * @return {!Array<!proto.web3oj.GetProblemsRequest.Filter.StatusFilter>}
 */
proto.web3oj.GetProblemsRequest.Filter.prototype.getStatusFilterList = function() {
  return /** @type {!Array<!proto.web3oj.GetProblemsRequest.Filter.StatusFilter>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.web3oj.GetProblemsRequest.Filter.StatusFilter>} value
 * @return {!proto.web3oj.GetProblemsRequest.Filter} returns this
 */
proto.web3oj.GetProblemsRequest.Filter.prototype.setStatusFilterList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.web3oj.GetProblemsRequest.Filter.StatusFilter} value
 * @param {number=} opt_index
 * @return {!proto.web3oj.GetProblemsRequest.Filter} returns this
 */
proto.web3oj.GetProblemsRequest.Filter.prototype.addStatusFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.GetProblemsRequest.Filter} returns this
 */
proto.web3oj.GetProblemsRequest.Filter.prototype.clearStatusFilterList = function() {
  return this.setStatusFilterList([]);
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.web3oj.GetProblemsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetProblemsRequest} returns this
 */
proto.web3oj.GetProblemsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 size = 2;
 * @return {number}
 */
proto.web3oj.GetProblemsRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetProblemsRequest} returns this
 */
proto.web3oj.GetProblemsRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string problem_title = 3;
 * @return {string}
 */
proto.web3oj.GetProblemsRequest.prototype.getProblemTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.GetProblemsRequest} returns this
 */
proto.web3oj.GetProblemsRequest.prototype.setProblemTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional OrderBy order_by = 4;
 * @return {!proto.web3oj.GetProblemsRequest.OrderBy}
 */
proto.web3oj.GetProblemsRequest.prototype.getOrderBy = function() {
  return /** @type {!proto.web3oj.GetProblemsRequest.OrderBy} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.web3oj.GetProblemsRequest.OrderBy} value
 * @return {!proto.web3oj.GetProblemsRequest} returns this
 */
proto.web3oj.GetProblemsRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Filter filter = 5;
 * @return {?proto.web3oj.GetProblemsRequest.Filter}
 */
proto.web3oj.GetProblemsRequest.prototype.getFilter = function() {
  return /** @type{?proto.web3oj.GetProblemsRequest.Filter} */ (
    jspb.Message.getWrapperField(this, proto.web3oj.GetProblemsRequest.Filter, 5));
};


/**
 * @param {?proto.web3oj.GetProblemsRequest.Filter|undefined} value
 * @return {!proto.web3oj.GetProblemsRequest} returns this
*/
proto.web3oj.GetProblemsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web3oj.GetProblemsRequest} returns this
 */
proto.web3oj.GetProblemsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web3oj.GetProblemsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web3oj.GetProblemsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetProblemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetProblemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetProblemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetProblemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    problemsList: jspb.Message.toObjectList(msg.getProblemsList(),
    api_web3oj_proto_problem_pb.Problem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetProblemsResponse}
 */
proto.web3oj.GetProblemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetProblemsResponse;
  return proto.web3oj.GetProblemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetProblemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetProblemsResponse}
 */
proto.web3oj.GetProblemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new api_web3oj_proto_problem_pb.Problem;
      reader.readMessage(value,api_web3oj_proto_problem_pb.Problem.deserializeBinaryFromReader);
      msg.addProblems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetProblemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetProblemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetProblemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetProblemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProblemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      api_web3oj_proto_problem_pb.Problem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_count = 1;
 * @return {number}
 */
proto.web3oj.GetProblemsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetProblemsResponse} returns this
 */
proto.web3oj.GetProblemsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Problem problems = 2;
 * @return {!Array<!proto.web3oj.Problem>}
 */
proto.web3oj.GetProblemsResponse.prototype.getProblemsList = function() {
  return /** @type{!Array<!proto.web3oj.Problem>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_web3oj_proto_problem_pb.Problem, 2));
};


/**
 * @param {!Array<!proto.web3oj.Problem>} value
 * @return {!proto.web3oj.GetProblemsResponse} returns this
*/
proto.web3oj.GetProblemsResponse.prototype.setProblemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.web3oj.Problem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.Problem}
 */
proto.web3oj.GetProblemsResponse.prototype.addProblems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.web3oj.Problem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.GetProblemsResponse} returns this
 */
proto.web3oj.GetProblemsResponse.prototype.clearProblemsList = function() {
  return this.setProblemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetProblemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetProblemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetProblemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetProblemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    problemId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetProblemRequest}
 */
proto.web3oj.GetProblemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetProblemRequest;
  return proto.web3oj.GetProblemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetProblemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetProblemRequest}
 */
proto.web3oj.GetProblemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChainId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProblemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetProblemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetProblemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetProblemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetProblemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProblemId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string chain_id = 2;
 * @return {string}
 */
proto.web3oj.GetProblemRequest.prototype.getChainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.GetProblemRequest} returns this
 */
proto.web3oj.GetProblemRequest.prototype.setChainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 problem_id = 3;
 * @return {number}
 */
proto.web3oj.GetProblemRequest.prototype.getProblemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetProblemRequest} returns this
 */
proto.web3oj.GetProblemRequest.prototype.setProblemId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetProblemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetProblemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetProblemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetProblemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    problem: (f = msg.getProblem()) && api_web3oj_proto_problem_pb.Problem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetProblemResponse}
 */
proto.web3oj.GetProblemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetProblemResponse;
  return proto.web3oj.GetProblemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetProblemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetProblemResponse}
 */
proto.web3oj.GetProblemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_web3oj_proto_problem_pb.Problem;
      reader.readMessage(value,api_web3oj_proto_problem_pb.Problem.deserializeBinaryFromReader);
      msg.setProblem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetProblemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetProblemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetProblemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetProblemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProblem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_web3oj_proto_problem_pb.Problem.serializeBinaryToWriter
    );
  }
};


/**
 * optional Problem problem = 1;
 * @return {?proto.web3oj.Problem}
 */
proto.web3oj.GetProblemResponse.prototype.getProblem = function() {
  return /** @type{?proto.web3oj.Problem} */ (
    jspb.Message.getWrapperField(this, api_web3oj_proto_problem_pb.Problem, 1));
};


/**
 * @param {?proto.web3oj.Problem|undefined} value
 * @return {!proto.web3oj.GetProblemResponse} returns this
*/
proto.web3oj.GetProblemResponse.prototype.setProblem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web3oj.GetProblemResponse} returns this
 */
proto.web3oj.GetProblemResponse.prototype.clearProblem = function() {
  return this.setProblem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web3oj.GetProblemResponse.prototype.hasProblem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetRankRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetRankRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetRankRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetRankRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userAddress: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetRankRequest}
 */
proto.web3oj.GetRankRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetRankRequest;
  return proto.web3oj.GetRankRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetRankRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetRankRequest}
 */
proto.web3oj.GetRankRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetRankRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetRankRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetRankRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetRankRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUserAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.web3oj.GetRankRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetRankRequest} returns this
 */
proto.web3oj.GetRankRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 size = 2;
 * @return {number}
 */
proto.web3oj.GetRankRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetRankRequest} returns this
 */
proto.web3oj.GetRankRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string user_address = 3;
 * @return {string}
 */
proto.web3oj.GetRankRequest.prototype.getUserAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.GetRankRequest} returns this
 */
proto.web3oj.GetRankRequest.prototype.setUserAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web3oj.GetRankResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetRankResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetRankResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetRankResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetRankResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    api_web3oj_proto_user_pb.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetRankResponse}
 */
proto.web3oj.GetRankResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetRankResponse;
  return proto.web3oj.GetRankResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetRankResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetRankResponse}
 */
proto.web3oj.GetRankResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new api_web3oj_proto_user_pb.User;
      reader.readMessage(value,api_web3oj_proto_user_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetRankResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetRankResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetRankResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetRankResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      api_web3oj_proto_user_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_count = 1;
 * @return {number}
 */
proto.web3oj.GetRankResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.GetRankResponse} returns this
 */
proto.web3oj.GetRankResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated User users = 2;
 * @return {!Array<!proto.web3oj.User>}
 */
proto.web3oj.GetRankResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.web3oj.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_web3oj_proto_user_pb.User, 2));
};


/**
 * @param {!Array<!proto.web3oj.User>} value
 * @return {!proto.web3oj.GetRankResponse} returns this
*/
proto.web3oj.GetRankResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.web3oj.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.User}
 */
proto.web3oj.GetRankResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.web3oj.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.GetRankResponse} returns this
 */
proto.web3oj.GetRankResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userAddress: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetUserRequest}
 */
proto.web3oj.GetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetUserRequest;
  return proto.web3oj.GetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetUserRequest}
 */
proto.web3oj.GetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_address = 1;
 * @return {string}
 */
proto.web3oj.GetUserRequest.prototype.getUserAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.GetUserRequest} returns this
 */
proto.web3oj.GetUserRequest.prototype.setUserAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web3oj.GetUserResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.GetUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.GetUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.GetUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && api_web3oj_proto_user_pb.User.toObject(includeInstance, f),
    userProblemHeatmapsList: jspb.Message.toObjectList(msg.getUserProblemHeatmapsList(),
    api_web3oj_proto_user_pb.UserProblemHeatmap.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.GetUserResponse}
 */
proto.web3oj.GetUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.GetUserResponse;
  return proto.web3oj.GetUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.GetUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.GetUserResponse}
 */
proto.web3oj.GetUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new api_web3oj_proto_user_pb.User;
      reader.readMessage(value,api_web3oj_proto_user_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new api_web3oj_proto_user_pb.UserProblemHeatmap;
      reader.readMessage(value,api_web3oj_proto_user_pb.UserProblemHeatmap.deserializeBinaryFromReader);
      msg.addUserProblemHeatmaps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.GetUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.GetUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.GetUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.GetUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      api_web3oj_proto_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getUserProblemHeatmapsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      api_web3oj_proto_user_pb.UserProblemHeatmap.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.web3oj.User}
 */
proto.web3oj.GetUserResponse.prototype.getUser = function() {
  return /** @type{?proto.web3oj.User} */ (
    jspb.Message.getWrapperField(this, api_web3oj_proto_user_pb.User, 1));
};


/**
 * @param {?proto.web3oj.User|undefined} value
 * @return {!proto.web3oj.GetUserResponse} returns this
*/
proto.web3oj.GetUserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web3oj.GetUserResponse} returns this
 */
proto.web3oj.GetUserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web3oj.GetUserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated UserProblemHeatmap user_problem_heatmaps = 2;
 * @return {!Array<!proto.web3oj.UserProblemHeatmap>}
 */
proto.web3oj.GetUserResponse.prototype.getUserProblemHeatmapsList = function() {
  return /** @type{!Array<!proto.web3oj.UserProblemHeatmap>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_web3oj_proto_user_pb.UserProblemHeatmap, 2));
};


/**
 * @param {!Array<!proto.web3oj.UserProblemHeatmap>} value
 * @return {!proto.web3oj.GetUserResponse} returns this
*/
proto.web3oj.GetUserResponse.prototype.setUserProblemHeatmapsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.web3oj.UserProblemHeatmap=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.UserProblemHeatmap}
 */
proto.web3oj.GetUserResponse.prototype.addUserProblemHeatmaps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.web3oj.UserProblemHeatmap, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.GetUserResponse} returns this
 */
proto.web3oj.GetUserResponse.prototype.clearUserProblemHeatmapsList = function() {
  return this.setUserProblemHeatmapsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.SignInRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.SignInRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.SignInRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.SignInRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    signedMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.SignInRequest}
 */
proto.web3oj.SignInRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.SignInRequest;
  return proto.web3oj.SignInRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.SignInRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.SignInRequest}
 */
proto.web3oj.SignInRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignedMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.SignInRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.SignInRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.SignInRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.SignInRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSignedMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string account_address = 1;
 * @return {string}
 */
proto.web3oj.SignInRequest.prototype.getAccountAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.SignInRequest} returns this
 */
proto.web3oj.SignInRequest.prototype.setAccountAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string signed_message = 2;
 * @return {string}
 */
proto.web3oj.SignInRequest.prototype.getSignedMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.SignInRequest} returns this
 */
proto.web3oj.SignInRequest.prototype.setSignedMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.SignInResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.SignInResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.SignInResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.SignInResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expiredAt: (f = msg.getExpiredAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.SignInResponse}
 */
proto.web3oj.SignInResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.SignInResponse;
  return proto.web3oj.SignInResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.SignInResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.SignInResponse}
 */
proto.web3oj.SignInResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiredAt(value);
      break;
    case 3:
      var value = /** @type {!proto.web3oj.SignInResponse.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.SignInResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.SignInResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.SignInResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.SignInResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpiredAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.web3oj.SignInResponse.State = {
  PENDING: 0,
  REGISTERD: 1
};

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.web3oj.SignInResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.SignInResponse} returns this
 */
proto.web3oj.SignInResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp expired_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.web3oj.SignInResponse.prototype.getExpiredAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.web3oj.SignInResponse} returns this
*/
proto.web3oj.SignInResponse.prototype.setExpiredAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web3oj.SignInResponse} returns this
 */
proto.web3oj.SignInResponse.prototype.clearExpiredAt = function() {
  return this.setExpiredAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web3oj.SignInResponse.prototype.hasExpiredAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional State state = 3;
 * @return {!proto.web3oj.SignInResponse.State}
 */
proto.web3oj.SignInResponse.prototype.getState = function() {
  return /** @type {!proto.web3oj.SignInResponse.State} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.web3oj.SignInResponse.State} value
 * @return {!proto.web3oj.SignInResponse} returns this
 */
proto.web3oj.SignInResponse.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.SignUpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.SignUpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.SignUpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.SignUpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    signedMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nickName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bio: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.SignUpRequest}
 */
proto.web3oj.SignUpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.SignUpRequest;
  return proto.web3oj.SignUpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.SignUpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.SignUpRequest}
 */
proto.web3oj.SignUpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignedMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.SignUpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.SignUpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.SignUpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.SignUpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSignedMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNickName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBio();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string account_address = 1;
 * @return {string}
 */
proto.web3oj.SignUpRequest.prototype.getAccountAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.SignUpRequest} returns this
 */
proto.web3oj.SignUpRequest.prototype.setAccountAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string signed_message = 2;
 * @return {string}
 */
proto.web3oj.SignUpRequest.prototype.getSignedMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.SignUpRequest} returns this
 */
proto.web3oj.SignUpRequest.prototype.setSignedMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string nick_name = 3;
 * @return {string}
 */
proto.web3oj.SignUpRequest.prototype.getNickName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.SignUpRequest} returns this
 */
proto.web3oj.SignUpRequest.prototype.setNickName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bio = 4;
 * @return {string}
 */
proto.web3oj.SignUpRequest.prototype.getBio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.SignUpRequest} returns this
 */
proto.web3oj.SignUpRequest.prototype.setBio = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.SignUpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.SignUpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.SignUpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.SignUpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expiredAt: (f = msg.getExpiredAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.SignUpResponse}
 */
proto.web3oj.SignUpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.SignUpResponse;
  return proto.web3oj.SignUpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.SignUpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.SignUpResponse}
 */
proto.web3oj.SignUpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.SignUpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.SignUpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.SignUpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.SignUpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpiredAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.web3oj.SignUpResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.SignUpResponse} returns this
 */
proto.web3oj.SignUpResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp expired_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.web3oj.SignUpResponse.prototype.getExpiredAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.web3oj.SignUpResponse} returns this
*/
proto.web3oj.SignUpResponse.prototype.setExpiredAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web3oj.SignUpResponse} returns this
 */
proto.web3oj.SignUpResponse.prototype.clearExpiredAt = function() {
  return this.setExpiredAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web3oj.SignUpResponse.prototype.hasExpiredAt = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.UpdateProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.UpdateProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.UpdateProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.UpdateProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nickName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bio: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.UpdateProfileRequest}
 */
proto.web3oj.UpdateProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.UpdateProfileRequest;
  return proto.web3oj.UpdateProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.UpdateProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.UpdateProfileRequest}
 */
proto.web3oj.UpdateProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.UpdateProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.UpdateProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.UpdateProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.UpdateProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNickName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBio();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string account_address = 1;
 * @return {string}
 */
proto.web3oj.UpdateProfileRequest.prototype.getAccountAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.UpdateProfileRequest} returns this
 */
proto.web3oj.UpdateProfileRequest.prototype.setAccountAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string nick_name = 2;
 * @return {string}
 */
proto.web3oj.UpdateProfileRequest.prototype.getNickName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.UpdateProfileRequest} returns this
 */
proto.web3oj.UpdateProfileRequest.prototype.setNickName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bio = 3;
 * @return {string}
 */
proto.web3oj.UpdateProfileRequest.prototype.getBio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.UpdateProfileRequest} returns this
 */
proto.web3oj.UpdateProfileRequest.prototype.setBio = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.UpdateProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.UpdateProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.UpdateProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.UpdateProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.UpdateProfileResponse}
 */
proto.web3oj.UpdateProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.UpdateProfileResponse;
  return proto.web3oj.UpdateProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.UpdateProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.UpdateProfileResponse}
 */
proto.web3oj.UpdateProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.UpdateProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.UpdateProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.UpdateProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.UpdateProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.web3oj);
