// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_APP = '/app';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_APP,
  app: {
    home: path(ROOTS_APP, '/home'),
    tutorial: path(ROOTS_APP, '/tutorial'),
    problem: path(ROOTS_APP, '/problem/list'),
    competitionProblem: path(ROOTS_APP, '/competition/problem/list'),
    rank: path(ROOTS_APP, '/user/rank'),
    group: path(ROOTS_APP, '/group/list'),
  },
  board: {
    root: path(ROOTS_APP, '/board'),
    list: path(ROOTS_APP, '/board/list'),
    posts: path(ROOTS_APP, '/board/posts'),
    post:{
      new: (boardId) => path(ROOTS_APP, `/board/post/new/${boardId}`),
      edit: (postId) => path(ROOTS_APP, `/board/post/edit/${postId}`),
      view: (postId) => path(ROOTS_APP, `/board/post/${postId}`),  
    },
    demoView: path(ROOTS_APP, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  career: {
    job: path(ROOTS_APP, '/career/job'),
    offer: path(ROOTS_APP, '/career/offer'),
  },
  general: {
    app: path(ROOTS_APP, '/home'),
    ecommerce: path(ROOTS_APP, '/ecommerce'),
    banking: path(ROOTS_APP, '/banking'),
    booking: path(ROOTS_APP, '/booking'),
  },
  mail: {
    root: path(ROOTS_APP, '/mail'),
    all: path(ROOTS_APP, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_APP, '/chat'),
    new: path(ROOTS_APP, '/chat/new'),
    view: (name) => path(ROOTS_APP, `/chat/${name}`),
  },
  calendar: path(ROOTS_APP, '/calendar'),
  kanban: path(ROOTS_APP, '/kanban'),
  permissionDenied: path(ROOTS_APP, '/permission-denied'),
  user: {
    root: path(ROOTS_APP, '/user'),
    new: path(ROOTS_APP, '/user/new'),
    list: path(ROOTS_APP, '/user/list'),
    cards: path(ROOTS_APP, '/user/cards'),
    profile: path(ROOTS_APP, '/user/profile'),
    account: path(ROOTS_APP, '/user/setting'),
    edit: (name) => path(ROOTS_APP, `/user/${name}/edit`),
    demoEdit: path(ROOTS_APP, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_APP, '/e-commerce'),
    shop: path(ROOTS_APP, '/e-commerce/shop'),
    list: path(ROOTS_APP, '/e-commerce/list'),
    checkout: path(ROOTS_APP, '/e-commerce/checkout'),
    new: path(ROOTS_APP, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_APP, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_APP, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_APP, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_APP, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_APP, '/invoice'),
    list: path(ROOTS_APP, '/invoice/list'),
    new: path(ROOTS_APP, '/invoice/new'),
    view: (id) => path(ROOTS_APP, `/invoice/${id}`),
    edit: (id) => path(ROOTS_APP, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_APP, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_APP, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_APP, '/blog'),
    posts: path(ROOTS_APP, '/blog/posts'),
    new: path(ROOTS_APP, '/blog/new'),
    view: (title) => path(ROOTS_APP, `/blog/post/${title}`),
    demoView: path(ROOTS_APP, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
