/**
 * @fileoverview gRPC-Web generated client stub for web3oj
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_web3oj_proto_common_pb = require('../../../api/web3oj/proto/common_pb.js')

var api_web3oj_proto_board_pb = require('../../../api/web3oj/proto/board_pb.js')
const proto = {};
proto.web3oj = require('./board_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.web3oj.BoardServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.web3oj.BoardServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.GetBoardRequest,
 *   !proto.web3oj.GetBoardResponse>}
 */
const methodDescriptor_BoardService_GetBoard = new grpc.web.MethodDescriptor(
  '/web3oj.BoardService/GetBoard',
  grpc.web.MethodType.UNARY,
  proto.web3oj.GetBoardRequest,
  proto.web3oj.GetBoardResponse,
  /**
   * @param {!proto.web3oj.GetBoardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.GetBoardResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.GetBoardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.GetBoardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.GetBoardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.BoardServiceClient.prototype.getBoard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.BoardService/GetBoard',
      request,
      metadata || {},
      methodDescriptor_BoardService_GetBoard,
      callback);
};


/**
 * @param {!proto.web3oj.GetBoardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.GetBoardResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.BoardServicePromiseClient.prototype.getBoard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.BoardService/GetBoard',
      request,
      metadata || {},
      methodDescriptor_BoardService_GetBoard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.ListBoardRequest,
 *   !proto.web3oj.ListBoardResponse>}
 */
const methodDescriptor_BoardService_ListBoard = new grpc.web.MethodDescriptor(
  '/web3oj.BoardService/ListBoard',
  grpc.web.MethodType.UNARY,
  proto.web3oj.ListBoardRequest,
  proto.web3oj.ListBoardResponse,
  /**
   * @param {!proto.web3oj.ListBoardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.ListBoardResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.ListBoardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.ListBoardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.ListBoardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.BoardServiceClient.prototype.listBoard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.BoardService/ListBoard',
      request,
      metadata || {},
      methodDescriptor_BoardService_ListBoard,
      callback);
};


/**
 * @param {!proto.web3oj.ListBoardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.ListBoardResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.BoardServicePromiseClient.prototype.listBoard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.BoardService/ListBoard',
      request,
      metadata || {},
      methodDescriptor_BoardService_ListBoard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.GetPostRequest,
 *   !proto.web3oj.GetPostResponse>}
 */
const methodDescriptor_BoardService_GetPost = new grpc.web.MethodDescriptor(
  '/web3oj.BoardService/GetPost',
  grpc.web.MethodType.UNARY,
  proto.web3oj.GetPostRequest,
  proto.web3oj.GetPostResponse,
  /**
   * @param {!proto.web3oj.GetPostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.GetPostResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.GetPostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.GetPostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.GetPostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.BoardServiceClient.prototype.getPost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.BoardService/GetPost',
      request,
      metadata || {},
      methodDescriptor_BoardService_GetPost,
      callback);
};


/**
 * @param {!proto.web3oj.GetPostRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.GetPostResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.BoardServicePromiseClient.prototype.getPost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.BoardService/GetPost',
      request,
      metadata || {},
      methodDescriptor_BoardService_GetPost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.ListPostRequest,
 *   !proto.web3oj.ListPostResponse>}
 */
const methodDescriptor_BoardService_ListPost = new grpc.web.MethodDescriptor(
  '/web3oj.BoardService/ListPost',
  grpc.web.MethodType.UNARY,
  proto.web3oj.ListPostRequest,
  proto.web3oj.ListPostResponse,
  /**
   * @param {!proto.web3oj.ListPostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.ListPostResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.ListPostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.ListPostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.ListPostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.BoardServiceClient.prototype.listPost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.BoardService/ListPost',
      request,
      metadata || {},
      methodDescriptor_BoardService_ListPost,
      callback);
};


/**
 * @param {!proto.web3oj.ListPostRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.ListPostResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.BoardServicePromiseClient.prototype.listPost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.BoardService/ListPost',
      request,
      metadata || {},
      methodDescriptor_BoardService_ListPost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.ListPostCommentRequest,
 *   !proto.web3oj.ListPostCommentResponse>}
 */
const methodDescriptor_BoardService_ListPostComment = new grpc.web.MethodDescriptor(
  '/web3oj.BoardService/ListPostComment',
  grpc.web.MethodType.UNARY,
  proto.web3oj.ListPostCommentRequest,
  proto.web3oj.ListPostCommentResponse,
  /**
   * @param {!proto.web3oj.ListPostCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.ListPostCommentResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.ListPostCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.ListPostCommentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.ListPostCommentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.BoardServiceClient.prototype.listPostComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.BoardService/ListPostComment',
      request,
      metadata || {},
      methodDescriptor_BoardService_ListPostComment,
      callback);
};


/**
 * @param {!proto.web3oj.ListPostCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.ListPostCommentResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.BoardServicePromiseClient.prototype.listPostComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.BoardService/ListPostComment',
      request,
      metadata || {},
      methodDescriptor_BoardService_ListPostComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.CreatePostRequest,
 *   !proto.web3oj.CreatePostResponse>}
 */
const methodDescriptor_BoardService_CreatePost = new grpc.web.MethodDescriptor(
  '/web3oj.BoardService/CreatePost',
  grpc.web.MethodType.UNARY,
  proto.web3oj.CreatePostRequest,
  proto.web3oj.CreatePostResponse,
  /**
   * @param {!proto.web3oj.CreatePostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.CreatePostResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.CreatePostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.CreatePostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.CreatePostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.BoardServiceClient.prototype.createPost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.BoardService/CreatePost',
      request,
      metadata || {},
      methodDescriptor_BoardService_CreatePost,
      callback);
};


/**
 * @param {!proto.web3oj.CreatePostRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.CreatePostResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.BoardServicePromiseClient.prototype.createPost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.BoardService/CreatePost',
      request,
      metadata || {},
      methodDescriptor_BoardService_CreatePost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.CreatePostCommentRequest,
 *   !proto.web3oj.CommonResponse>}
 */
const methodDescriptor_BoardService_CreatePostComment = new grpc.web.MethodDescriptor(
  '/web3oj.BoardService/CreatePostComment',
  grpc.web.MethodType.UNARY,
  proto.web3oj.CreatePostCommentRequest,
  api_web3oj_proto_common_pb.CommonResponse,
  /**
   * @param {!proto.web3oj.CreatePostCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_web3oj_proto_common_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.CreatePostCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.BoardServiceClient.prototype.createPostComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.BoardService/CreatePostComment',
      request,
      metadata || {},
      methodDescriptor_BoardService_CreatePostComment,
      callback);
};


/**
 * @param {!proto.web3oj.CreatePostCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.CommonResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.BoardServicePromiseClient.prototype.createPostComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.BoardService/CreatePostComment',
      request,
      metadata || {},
      methodDescriptor_BoardService_CreatePostComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.UpdatePostRequest,
 *   !proto.web3oj.CommonResponse>}
 */
const methodDescriptor_BoardService_UpdatePost = new grpc.web.MethodDescriptor(
  '/web3oj.BoardService/UpdatePost',
  grpc.web.MethodType.UNARY,
  proto.web3oj.UpdatePostRequest,
  api_web3oj_proto_common_pb.CommonResponse,
  /**
   * @param {!proto.web3oj.UpdatePostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_web3oj_proto_common_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.UpdatePostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.BoardServiceClient.prototype.updatePost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.BoardService/UpdatePost',
      request,
      metadata || {},
      methodDescriptor_BoardService_UpdatePost,
      callback);
};


/**
 * @param {!proto.web3oj.UpdatePostRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.CommonResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.BoardServicePromiseClient.prototype.updatePost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.BoardService/UpdatePost',
      request,
      metadata || {},
      methodDescriptor_BoardService_UpdatePost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.UpdatePostCommentRequest,
 *   !proto.web3oj.CommonResponse>}
 */
const methodDescriptor_BoardService_UpdatePostComment = new grpc.web.MethodDescriptor(
  '/web3oj.BoardService/UpdatePostComment',
  grpc.web.MethodType.UNARY,
  proto.web3oj.UpdatePostCommentRequest,
  api_web3oj_proto_common_pb.CommonResponse,
  /**
   * @param {!proto.web3oj.UpdatePostCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_web3oj_proto_common_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.UpdatePostCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.BoardServiceClient.prototype.updatePostComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.BoardService/UpdatePostComment',
      request,
      metadata || {},
      methodDescriptor_BoardService_UpdatePostComment,
      callback);
};


/**
 * @param {!proto.web3oj.UpdatePostCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.CommonResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.BoardServicePromiseClient.prototype.updatePostComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.BoardService/UpdatePostComment',
      request,
      metadata || {},
      methodDescriptor_BoardService_UpdatePostComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.DeletePostRequest,
 *   !proto.web3oj.CommonResponse>}
 */
const methodDescriptor_BoardService_DeletePost = new grpc.web.MethodDescriptor(
  '/web3oj.BoardService/DeletePost',
  grpc.web.MethodType.UNARY,
  proto.web3oj.DeletePostRequest,
  api_web3oj_proto_common_pb.CommonResponse,
  /**
   * @param {!proto.web3oj.DeletePostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_web3oj_proto_common_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.DeletePostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.BoardServiceClient.prototype.deletePost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.BoardService/DeletePost',
      request,
      metadata || {},
      methodDescriptor_BoardService_DeletePost,
      callback);
};


/**
 * @param {!proto.web3oj.DeletePostRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.CommonResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.BoardServicePromiseClient.prototype.deletePost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.BoardService/DeletePost',
      request,
      metadata || {},
      methodDescriptor_BoardService_DeletePost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.DeletePostCommentRequest,
 *   !proto.web3oj.CommonResponse>}
 */
const methodDescriptor_BoardService_DeletePostComment = new grpc.web.MethodDescriptor(
  '/web3oj.BoardService/DeletePostComment',
  grpc.web.MethodType.UNARY,
  proto.web3oj.DeletePostCommentRequest,
  api_web3oj_proto_common_pb.CommonResponse,
  /**
   * @param {!proto.web3oj.DeletePostCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_web3oj_proto_common_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.DeletePostCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.BoardServiceClient.prototype.deletePostComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.BoardService/DeletePostComment',
      request,
      metadata || {},
      methodDescriptor_BoardService_DeletePostComment,
      callback);
};


/**
 * @param {!proto.web3oj.DeletePostCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.CommonResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.BoardServicePromiseClient.prototype.deletePostComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.BoardService/DeletePostComment',
      request,
      metadata || {},
      methodDescriptor_BoardService_DeletePostComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.SetPostEmojiRequest,
 *   !proto.web3oj.CommonResponse>}
 */
const methodDescriptor_BoardService_SetPostEmoji = new grpc.web.MethodDescriptor(
  '/web3oj.BoardService/SetPostEmoji',
  grpc.web.MethodType.UNARY,
  proto.web3oj.SetPostEmojiRequest,
  api_web3oj_proto_common_pb.CommonResponse,
  /**
   * @param {!proto.web3oj.SetPostEmojiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_web3oj_proto_common_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.SetPostEmojiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.BoardServiceClient.prototype.setPostEmoji =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.BoardService/SetPostEmoji',
      request,
      metadata || {},
      methodDescriptor_BoardService_SetPostEmoji,
      callback);
};


/**
 * @param {!proto.web3oj.SetPostEmojiRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.CommonResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.BoardServicePromiseClient.prototype.setPostEmoji =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.BoardService/SetPostEmoji',
      request,
      metadata || {},
      methodDescriptor_BoardService_SetPostEmoji);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.SetCommentEmojiRequest,
 *   !proto.web3oj.CommonResponse>}
 */
const methodDescriptor_BoardService_SetCommentEmoji = new grpc.web.MethodDescriptor(
  '/web3oj.BoardService/SetCommentEmoji',
  grpc.web.MethodType.UNARY,
  proto.web3oj.SetCommentEmojiRequest,
  api_web3oj_proto_common_pb.CommonResponse,
  /**
   * @param {!proto.web3oj.SetCommentEmojiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  api_web3oj_proto_common_pb.CommonResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.SetCommentEmojiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.CommonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.CommonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.BoardServiceClient.prototype.setCommentEmoji =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.BoardService/SetCommentEmoji',
      request,
      metadata || {},
      methodDescriptor_BoardService_SetCommentEmoji,
      callback);
};


/**
 * @param {!proto.web3oj.SetCommentEmojiRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.CommonResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.BoardServicePromiseClient.prototype.setCommentEmoji =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.BoardService/SetCommentEmoji',
      request,
      metadata || {},
      methodDescriptor_BoardService_SetCommentEmoji);
};


module.exports = proto.web3oj;

