// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const jp = {
  Home: {
    getStarted: 'はじめる',
    HomeHero: {
      section1: {
        1: 'ブロックチェーン開発者として',
        2: '成長する!',
      },
      section2: 'Web3OJは、ブロックチェーン開発者として技術を向上させ、知識を学び、技術面接の準備をするのに役立つ最高のブロックチェーンオンラインジャッジプラットフォームです。',
    },
    HomeMinimal: {
      Card: {
        Problem: {
          title: '問題',
          description: 'ブロックチェーン開発者として成長できる問題を提供し、オンラインジャッジを通じて学習の成果を検証できます。',
        },
        Community: {
          title: 'コミュニティ',
          description: 'ブロックチェーン開発の勉強ができるコミュニティがあります。新しいグループを作成したり、既存のグループに参加したりして参加してください！',
        },
        Recruitment: {
          title: '開発者採用',
          description: 'Web3OJは技術面接の準備だけでなく、企業の最高の人材を採用できるよう支援しています。オンライン評価と教育を通じて人材発掘と高度な人材育成サービスを提供しています。',
        },
      },
      cardTitle: 'こうしたサービスを提供しています',
    },
    HomeAdvertisement: {
      section1: {
        1: 'Web3OJで',
        2: 'ブロックチェーンキャリアをスタート',
      },
    },
  },
  intro: '紹介',
  problem: '問題',
  Study: '学習',
  rank: 'ランキング',
  competition: 'コンペティション',
  board: '掲示板',
  group: 'グループ',
  kakaochat: 'カカオトークオープンチャット',
  linechat: 'ラインオープンチャット',
  discord: 'ディスコードコミュニティ',
  Community: 'コミュニティ',
  DeveloperRecruitment: '開発者募集',
  Jobposting: '採用募集（準備中）',
  Offer: '採用提案を受け取る（準備中）',
  ConnectMetamask: 'MetaMask接続',
  UnsupportedBlockchain: 'サポートされていないブロックチェーンです。',
  user: 'ユーザー',
  tutorial: 'チュートリアル',
  dashboard: {
    welcomeBox: {
      title: 'ようこそ！',
      description: 'Web3OJは、ブロックチェーン開発者として技術を向上させ、知識を学び、技術面接の準備をするのに役立つ最高のブロックチェーンオンラインジャッジプラットフォームです。',
      tutorial: 'チュートリアル',
    },
    service: {
      title: 'サービス',
      StudyDescription: '問題を解いてランキングを上げましょう。',
      CommunityDescription: 'コミュニティを通じて他の人々とコミュニケーションを取りましょう。',
      DeveloperRecruitmentDescription: 'ブロックチェーン開発者として採用提案を受け取りましょう。',
    },
    LatestPost: '最新の投稿',
    Top3User: 'Top3ユーザー',
  },
  Problem: {
    create: '問題解く - 問題インスタンス生成',
    creating: '問題作成中',
    title: '問題',
    difficulty: '難易度',
    solver: '解いた人',
    submit: '提出',
    submitting: '提出中',
    status: '合格可否',
    board: '議論掲示板',
    contractInstanceAddress : '問題コントラクトインスタンスアドレス',
    reference: '出典',
    Dialog: {
      load: '問題コントラクトインスタンスをロード',
      loadDescription: '以前に作成した問題コントラクトインスタンスが存在します。ロードしますか？',
      Button: {
        delete: '削除する',
        skip: 'スキップ',
        load: 'ロード',
      }
    },
    message: {
      connectMetamask: 'MetaMaskを接続してください。',
      success: '合格しました〜！！',
      fail: '失敗',
      completeSubmit: '提出が完了しました。',
      noSubmission: '提出者がいません。',
    },
    ContributeType: {
      CREATE: '出題',
      TRANSLATION: '翻訳',
      CORRECTION: '問題訂正',
    }
  },
  CompetitionProblem: {
    title: 'コンペティション',
    difficulty: '難易度',
    solver: '解いた人',
    submit: '問題提出数',
    CompetitionType: {
      COMMUNITY: 'コミュニティコンペティション',
    },
    TABLE_HEAD: {
      rank: 'ランキング',
      nickname: 'ニックネーム',
      introduction: '紹介',
      score: 'スコア',
      datetime: '日付/時間',
    },
    Tab: {
      description: '問題説明',
      discuss: '議論掲示板',
      rank: 'ランキング',
    },
  },
  Board: {
    post: '投稿',
    noPost: '投稿がありません。',
    title: 'タイトル',
    nickname: '投稿者',
    viewCount: '閲覧数',
    commentCount: 'コメント数',
    displayTime: '投稿時刻',
    Post: {
      title: '投稿',
      subscribe: '通知を受け取る',
      subscribed: '通知受信中！',
      Setting: {
        title: '投稿設定',
        delete: '削除',
        edit: '編集',
        cancel: 'キャンセル',
      }
    },
    Comment: {
      comment: 'コメント',
      commentWrite: 'コメント投稿',
      commentWriteButton: 'コメント投稿',
      Setting: {
        title: 'コメント設定',
        delete: '削除',
        edit: '編集',
        cancel: 'キャンセル',
      }
    }
  },
  PostEditor: {
    post: '投稿',
    write: '投稿作成',
    edit: '投稿編集',
    submit: '提出',
    title: 'タイトル',
    dummyContent: '## 内容\n**Markdown**で内容を記述してください。\n\n## 質問掲示板\n\n質問掲示板の場合、問題の**回答に関するソースコードの公開は制限**されます。',
    message: {
      writeFail: '投稿作成に失敗しました。',
      editFail: '投稿編集に失敗しました。',
    }
  },
  Rank: {
    title: 'ランキング',
    rank: '順位',
    nickname: 'ニックネーム',
    introduction: '紹介',
    solved: '解いた問題数',
  },
  User: {
    profile: 'プロフィール',
    solvedProblem: '合格した問題',
    createdProblem: '出題した問題',
    group: 'グループ',
    noSolvedProblem: '合格した問題はありません。',
    noCreatedProblem: '出題した問題はありません。',
    noGroup: 'グループがありません。',
  },
  UserCreate: {
    title: '会員登録',
    modifyTitle: 'プロフィール編集',
    wallet: 'ウォレット',
    nickname: 'ニックネーム',
    introduction: '紹介',
    button: {
      signUp: '会員登録',
      update: '更新',
    },
    message: {
      connectMetamask: 'MetaMaskでウォレットを接続してください',
      tooShortNickname: 'ニックネームが短すぎます。2文字以上で入力してください。',
      tooShortIntroduction: '紹介が短すぎます。5文字以上で入力してください。',
      notSignUpUser: '会員登録されたユーザーではありません。登録してください！',
      successModify: 'プロフィールの更新に成功しました。',
      failModify: 'プロフィールの更新に失敗しました。',
    },
  },
  MainBoard: {
    title: '掲示板',
    notice: 'お知らせ',
    free: 'フリー掲示板',
    question: '質問掲示板',
    update: 'アップデート',
    DApp: 'DApp',
    NFT: 'NFT',
    DeFi: 'DeFi',
    promotion: 'プロモーション掲示板',
    'お知らせ': 'お知らせ',
    'フリー掲示板': 'フリー掲示板',
    '質問掲示板': '質問掲示板',
    'アップデート': 'アップデート',
    'DApp開発掲示板': 'DApp開発掲示板',
    'NFT掲示板': 'NFT掲示板',
    'DeFi掲示板': 'DeFi掲示板',
    'プロモーション掲示板': 'プロモーション掲示板',
  },
  Group: {
    title: 'グループ',
    id: 'ID',
    name: 'グループ名',
    type: 'グループタイプ',
    leader: 'リーダー',
    member: 'メンバー数',
    create: 'グループ作成',
    createTitle: 'グループ作成する',
    groupType: 'グループタイプ',
    groupName: 'グループ名',
    groupCode: '入場コード',
    groupCodeDescription: '入場コード - プライベートグループに入るために必要な入場コードです',
    cancel: 'キャンセル',
    group: 'グループ',
    join: '参加リクエスト',
    save: '保存',
    delete: 'グループ削除',
    modifyGroupCodeDescription: '入場コード - 変更しない場合は空白にしてください。',
    Tab: {
        board: 'グループ掲示板',
        member: 'メンバー',
        joinRequest: '参加リクエスト'
    },
    noJoinRequest: '参加リクエストはありません。',
    rankSuffix: '位',
    joinRequestList: '参加リクエスト一覧',
    rejectList: '拒否一覧',
    approve: '承認',
    reject: '拒否',
    joinRequested: '参加リクエスト済み',
    setting: '設定',
    message: {
        createSuccess: 'グループ作成に成功しました。',
        createFail: 'グループ作成に失敗しました。',
        modifySuccess: 'グループ編集に成功しました。',
        modifyFail: 'グループ編集に失敗しました。',
        deleteSuccess: 'グループ削除に成功しました。',
        deleteFail: 'グループ削除に失敗しました。',
        joinRequestSuccess: '参加リクエストに成功しました。',
        joinRequestFail: '参加リクエストに失敗しました。',
        approveSuccess: '参加リクエストの承認に成功しました。',
        approveFail: '参加リクエストの承認に失敗しました。',
        rejectSuccess: '参加リクエストの拒否に成功しました。',
        rejectFail: '参加リクエストの拒否に失敗しました。',
        joinSuccess: 'グループ参加に成功しました。',
        joinFail: 'グループ参加に失敗しました。',
    },
    TABLE_HEAD: {
        rank: 'ランキング',
        nickname: 'ニックネーム',
        address: 'ウォレットアドレス',
        introduction: '紹介',
        solvedProblem: '解決済み問題',
        action: '承認/拒否',
        approve: '承認',
    }
  },
  EASY: '簡単',
  MEDIUM: '普通',
  HARD: '難しい',
};

export default jp;
