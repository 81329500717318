import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// components
import { updateCommentNonce } from '../../../redux/slices/post';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import SnackbarAlert from '../../../components/SnackbarAlert';
import { AllReadNotificationRequest, ReadNotificationRequest, ListNotificationRequest, OnNotificationReceivedRequest } from '../../../api/web3oj/proto/notification_service_pb';
import Web3OJClient from '../../../api/web3oj/cilent';


// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState(null);

  const [totalUnRead, setTotalUnread] = useState(0);
  const accountAddress = useSelector((state) => state.metamask.address);
  const isSignIn = useSelector((state) => state.metamask.isSignIn);

  const [open, setOpen] = useState(null);
  const [nonce, setNonce] = useState(0);
  const [isConnection, setIsConnection] = useState(false);

  // SnackbarAlert
  const [message, setMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [serverity, setServerity] = useState('success');

  function alertSuccess(message) {
    setServerity('success');
    setMessage(message);
    setSnackbarOpen(true);
  }

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const currentUrl = window.location.pathname;
  const urlArr = currentUrl.split('/');
  console.log();
  
  function receiveNotification() {
    const auth = sessionStorage.getItem('AccessToken');
    if (auth != null && accountAddress != null) {
      const onNotificationReceivedRequest = new OnNotificationReceivedRequest();
      const metadata = { Authorization: auth };
      const stream = Web3OJClient.notificationService.onNotificationReceived(onNotificationReceivedRequest, metadata)
      setIsConnection(true);
      stream.on('data', (response) => {
        setIsConnection(true);
        const notification = response.getNotification();
        alertSuccess(notification.getContent());
        getListNotification();
        
        // 지금 보고 있는 게시글에 대한 알림이면 댓글을 가져올수 있도록 commentNonce값을 업데이트 한다.
        const currentUrl = window.location.pathname;
        const urlArr = currentUrl.split('/');
        console.log(urlArr[urlArr.length - 1]);
        if (urlArr.length > 2 && notification.getRefKey() === 'post' && urlArr[urlArr.length - 2] === 'post' && notification.getRefValue() === urlArr[urlArr.length - 1]) {
          dispatch(updateCommentNonce());
        }
        setNonce(nonce+1);
      });
      // stream.on('status', (status) => {
      //   console.log("receive status", status.code);
      // });
      stream.on('end', () => {
        console.log("receive end");
        // setIsConnection(false);
      });
    }
  }

  function getListNotification() {
    const auth = sessionStorage.getItem('AccessToken');
    if (auth != null && accountAddress != null) {
      const listNotificationRequest = new ListNotificationRequest();
      listNotificationRequest.setPage(0);
      listNotificationRequest.setSize(5);
      const metadata = { Authorization: auth };
      Web3OJClient.notificationService.listNotification(listNotificationRequest, metadata, (err, response) => {
        if (err == null) {
          setTotalUnread(response.getUnreadCount());
          setNotifications(response.getNotificationsList());
        }
      });
    } else {
      setTotalUnread(0);
    }
  }

  function allReadNotification() {
    const auth = sessionStorage.getItem('AccessToken');
    if (auth != null && accountAddress != null) {
      const allReadNotificationRequest = new AllReadNotificationRequest();
      const metadata = { Authorization: auth };
      Web3OJClient.notificationService.allReadNotification(allReadNotificationRequest, metadata, (err, response) => {
        if (err == null) {
          getListNotification();
        }
      });
    }
  }

  useEffect(() => {
    receiveNotification();
    getListNotification();
  }, [totalUnRead, nonce, isSignIn]);

  return (
    <>
      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        message={message}
        serverity={serverity}
      />
      {isConnection && 
        <>
          <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">알림</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              읽지 않은 메시지 {totalUnRead}개
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title="안읽은 메시지 모두 읽기 처리">
              <IconButton color="primary" onClick={()=> {
                allReadNotification();
              }}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                알림 목록
              </ListSubheader>
            }
          >
            {!notifications && (
              <>
                <Typography variant="body1" sx={{ color: 'text.secondary', m: 4 }}>
                  😅 알림이 없습니다
                </Typography>
              </>
            )} 
            {notifications && notifications.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} nonce={nonce} setNonce={setNonce} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={ () => {
            navigate('/app/notification/list');  
          }}>
            모든 알림 보기
          </Button>
        </Box>
      </MenuPopover>
        </>
      }
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
};

function NotificationItem({ notification, nonce, setNonce }) {
  const { avatar, title } = renderContent(notification);
  
  const navigate = useNavigate();
  function goToRef(refKey, refValue){
    if (refKey === 'post') {
      navigate('/app/board/post/'.concat(refValue));  
    }
  };

  function readNotification(notificationId) {
    const auth = sessionStorage.getItem('AccessToken');
    if (auth != null) {
      const readNotificationRequest = new ReadNotificationRequest();
      readNotificationRequest.setNotificationId(notificationId);
      const metadata = { Authorization: auth };
      Web3OJClient.notificationService.readNotification(readNotificationRequest, metadata, (err, response) => {
        setNonce(nonce+1);
      });
    }
  }

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.getIsRead() && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => {
        readNotification(notification.getId());
        goToRef(notification.getRefKey(), notification.getRefValue());
        notification.setIsRead(true);
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {notification.getDisplayTime()}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.getContent()}
    </Typography>
  );

  
  if (notification.type === 'chat_message') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
