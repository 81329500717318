// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const kr = {
    Home: {
      getStarted: '시작하기',
      HomeHero: {
        section1: {
          1: '블록체인 개발자로',
          2: '성장하기!',
        },
        section2: 'Web3OJ는 블록체인 개발자로서 기술을 향상하고 지식을 배울수 있으며 기술 면접을 준비하는 데 도움이 되는 최고의 블록체인 온라인 저지 플랫폼입니다.',
      },
      HomeMinimal: {
        Card: {
          Problem: {
            title: '문제',
            description: '블록체인 개발자로서 성장할수 있는 문제들을 제공하며 온라인 저지를 통해서 학습의 결과를 검증할 수 있습니다.',
          },
          Community: {
            title: '커뮤니티',
            description: '블록체인 개발 공부를 할수 있는 커뮤니티가 있습니다. 그룹을 새로 만들거나 기존 그룹에 가입하여 참여하세요!',
          },
          Recruitment: {
            title: '개발자 채용',
            description: 'Web3OJ는 기술면접을 준비할 뿐만 아니라 기업의 최고의 인재를 채용할수 있도록 지원하고 있습니다. 온라인 평가 및 교육제공을 통해 인재발굴과 고급인재 양성서비스를 제공하고 있습니다.',
          },
        },
        cardTitle: '이러한 서비스를 제공하고 있어요',
      },
      HomeAdvertisement: {
        section1: {
          1: 'Web3OJ로',
          2: '블록체인 커리어를 시작하세요'
        },
      },
    },
    intro: '소개',
    problem: '문제',
    Study: '학습',
    rank: '랭킹',
    competition: '대회',
    board: '게시판',
    group: '그룹',
    kakaochat: '카카오톡 오픈 채팅방',
    linechat: '라인 오픈 채팅방',
    discord: '디스코드 커뮤니티',
    Community: '커뮤니티',
    DeveloperRecruitment: '개발자 채용',
    Jobposting: '채용 공고 올리기(준비 중)',
    Offer: '채용 제안 받기(준비 중)',
    ConnectMetamask: '메타마스크 연결',
    UnsupportedBlockchain: '지원하지 않는 블록체인입니다.',
    user: '유저',
    tutorial: '튜토리얼',
    dashboard: {
      welcomeBox: {
        title: '반갑습니다!',
        description: 'Web3OJ는 블록체인 개발자로서 기술을 향상하고 지식을 배울수 있으며 기술 면접을 준비하는 데 도움이 되는 최고의 블록체인 온라인 저지 플랫폼입니다.',
        tutorial: '튜토리얼',
      },
      service: {
        title: '서비스',
        StudyDescription: '문제를 풀고 랭킹을 올리세요.',
        CommunityDescription: '커뮤니티를 통해 다른 사람들과 소통하세요.',
        DeveloperRecruitmentDescription: '블록체인 개발자로 채용 제안을 받아보세요',
      },
      LatestPost: '최신 게시글',
      Top3User: 'Top3 유저',
    },
    Problem: {
      create: '문제풀기 - 문제 인스턴스 생성',
      creating: '문제 생성 중',
      title: '문제',
      difficulty: '난이도',
      solver: '푼 사람',
      submit: '제출',
      submitting: '제출 중',
      status: '통과여부',
      board: '논의 게시판',
      contractInstanceAddress : '문제 컨트랙트 인스턴스 주소',
      reference: '출처',
      Dialog: {
        load: '문제 컨트랙트 인스턴스 불러오기',
        loadDescription: '이전에 생성한 문제 컨트랙트 인스턴스가 존재합니다. 불러오시겠습니까?',
        Button: {
          delete: '삭제하기',
          skip: '넘어가기',
          load: '불러오기',
        }
      },
      message: {
        connectMetamask: '메타마스크를 연결해주세요.',
        success: '통과했습니다~!!',
        fail: '실패',
        completeSubmit: '제출이 완료되었습니다.',
        noSubmission: '제출한 사람이 없습니다.',
      },
      ContributeType: {
        CREATE: '출제',
        TRANSLATION: '번역',
        CORRECTION: '문제 정정',
      }
    },
    CompetitionProblem: {
      title: '대회',
      difficulty: '난이도',
      solver: '푼 사람',
      submit: '문제 제출 수',
      CompetitionType: {
        COMMUNITY: '커뮤니티 대회',
      },
      TABLE_HEAD: {
        rank: '랭킹',
        nickname: '닉네임',
        introduction: '소개',
        score: '점수',
        datetime: '날짜/시간',
      },
      Tab: {
        description: '문제 설명',
        discuss: '논의 게시판',
        rank: '랭킹',
      },
    },
    Board: {
      post: '글쓰기',
      noPost: '게시글이 없습니다.',
      title: '제목',
      nickname: '작성자',
      viewCount: '조회수',
      commentCount: '댓글수',
      displayTime: '작성시각',
      Post: {
        title: '게시글',
        subscribe: '알림 받기',
        subscribed: '알림 받는 중!',
        Setting: {
          title: '게시글 설정',
          delete: '삭제',
          edit: '수정',
          cancel: '취소',
        }
      },
      Comment: {
        comment: '댓글',
        commentWrite: '댓글 작성',
        commentWriteButton: '댓글 작성',
        Setting: {
          title: '댓글 설정',
          delete: '삭제',
          edit: '수정',
          cancel: '취소',
        }
      }
    },
    PostEditor: {
      post: '글쓰기',
      write: '게시글 쓰기',
      edit: '게시글 수정',
      submit: '제출',
      title: '제목',
      dummyContent: '## 내용\n**Markdown**으로 내용 작성하시면 됩니다. \n\n ## 질문게시판 \n\n 질문게시판의 경우 문제의 **정답에 대한 소스코드 공개는 제한**됩니다.',
      message: {
        writeFail: '게시글 작성에 실패했습니다. ',
        editFail: '게시글 수정에 실패했습니다. ',
      }
    },
    Rank: {
      title: '랭킹',
      rank: '등수',
      nickname: '닉네임',
      introduction: '소개',
      solved: '푼 문제 수',
    },
    User: {
      profile: '프로필',
      solvedProblem: '통과한 문제',
      createdProblem: '출제한 문제',
      group: '그룹',
      noSolvedProblem: '통과한 문제가 없습니다.',
      noCreatedProblem: '출제한 문제가 없습니다.',
      noGroup: '그룹이 없습니다.',
    },
    UserCreate: {
      title: '회원가입',
      modifyTitle: '프로필 수정',
      wallet: '지갑',
      nickname: '닉네임',
      introduction: '소개',
      button: {
        signUp: '회원가입',
        update: '수정',
      },
      message: {
        connectMetamask: '메타마스크로 지갑 연결을 해주세요',
        tooShortNickname: '닉네임 길이가 너무 짧습니다. 2글자 이상으로 작성해주세요.',
        tooShortIntroduction: '소개 길이가 너무 짧습니다. 5글자 이상으로 작성해주세요.',
        notSignUpUser: '회원가입된 유저가 아닙니다. 가입해주세요!',
        successModify: '프로필 수정에 성공했습니다.',
        failModify: '프로필 수정에 실패했습니다.',
      },
    },
    MainBoard: {
      title: '게시판',
      notice: '공지사항',
      free: '자유게시판',
      question: '질문게시판',
      update: '업데이트',
      DApp: 'DApp',
      NFT: 'NFT',
      DeFi: 'DeFi',
      promotion: '홍보게시판',
      '공지사항': '공지사항',
      '자유 게시판': '자유 게시판',
      '질문 게시판': '질문 게시판',
      '업데이트': '업데이트',
      'DApp 개발 게시판': 'DApp 개발 게시판',
      'NFT 게시판': 'NFT 게시판',
      'DeFi 게시판': 'DeFi 게시판',
      '홍보 게시판': '홍보 게시판',
    },
    Group: {
      title: '그룹',
      id: 'id',
      name: '그룹명',
      type: '그룹 타입',
      leader: '그룹장',
      member: '멤버 수',
      create: '그룹 생성',
      createTitle: '그룹 생성하기',
      groupType: '그룹 타입',
      groupName: '그룹명',
      groupCode: '입장 코드',
      groupCodeDescription: '입장코드 - Private Group에 들어오기 위해 필요한 입장코드 입니다',
      cancel: '취소',
      group: '그룹',
      join: '가입 요청',
      save: '저장',
      delete: '그룹 삭제',
      modifyGroupCodeDescription: '입장코드 - 수정하지 않으시려면 비워두시면 됩니다.',
      Tab: {
        board: '그룹 게시판',
        member: '멤버',
        joinRequest: '가입 요청'
      },
      noJoinRequest: '가입 요청이 없습니다.',
      rankSuffix: '위',
      joinRequestList: '가입 요청 목록',
      rejectList: '거절 목록',
      approve: '승인',
      reject: '거절',
      joinRequested: '가입 요청됨',
      setting: '설정',
      message: {
        createSuccess: '그룹 생성에 성공하였습니다.',
        createFail: '그룹 생성에 실패하였습니다.',
        modifySuccess: '그룹 수정에 성공하였습니다.',
        modifyFail: '그룹 수정에 실패하였습니다.',
        deleteSuccess: '그룹 삭제에 성공하였습니다.',
        deleteFail: '그룹 삭제에 실패하였습니다.',
        joinRequestSuccess: '가입 요청에 성공하였습니다.',
        joinRequestFail: '가입 요청에 실패하였습니다.',
        approveSuccess: '가입 요청 승인에 성공하였습니다.',
        approveFail: '가입 요청 승인에 실패하였습니다.',
        rejectSuccess: '가입 요청 거절에 성공하였습니다.',
        rejectFail: '가입 요청 거절에 실패하였습니다.',
        joinSuccess: '그룹 가입에 성공하였습니다.',
        joinFail: '그룹 가입에 실패하였습니다.',
      },
      TABLE_HEAD: {
        rank: '랭킹',
        nickname: '닉네임',
        address: '지갑 주소',
        introduction: '소개',
        solvedProblem: '통과한 문제',
        action: '승인/거절',
        approve: '승인',
      }
    },
    EASY: '쉬움',
    MEDIUM: '보통',
    HARD: '어려움',
  };
  
  export default kr;
  