// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  Home: {
    getStarted: 'Get Started',
    HomeHero: {
      section1: {
        1: 'Becoming',
        2: 'a blockchain developer',
      },
      section2: 'Web3OJ is the best blockchain online judge platform to help you as a blockchain developer improve your skills, learn knowledge and prepare for technical interviews.',
    },
    HomeMinimal: {
      Card: {
        Problem: {
          title: 'Problem',
          description: 'It provides problems to grow as a blockchain developer and can verify the results of learning through online judge.',
        },
        Community: {
          title: 'Community',
          description: 'There is a community where you can study blockchain development. Join by creating a new group or joining an existing group!',
        },
        Recruitment: {
          title: 'Developer Recruitment',
          description: 'Web3OJ not only prepares for the technical interview, but also supports the recruitment of the best talent in the company. Through online evaluation and training, we provide talent discovery and high-quality talent nurturing services.',
        },
      },
      cardTitle: 'We provide below services',
    },
    HomeAdvertisement: {
      section1: {
        1: 'Start Your Blockchain Career ',
        2: 'with Web3OJ'
      },
    },
  },
  intro: 'Introduction',
  problem: 'Problem',
  Study: 'Study',
  rank: 'Leaderboard',
  competition: 'Competition',
  board: 'Forum',
  group: 'Group',
  kakaochat: 'KakaoTalk Open Chat',
  linechat: 'Line Open Chat',
  discord: 'Discord Community',
  Community: 'Community',
  DeveloperRecruitment: 'Developer Recruitment',
  Jobposting: 'Job Posting(Preparing)',
  Offer: 'Offer(Preparing)',
  ConnectMetamask: 'Connect Metamask',
  UnsupportedBlockchain: 'Unsupported blockchain',
  user: 'User',
  tutorial: 'Tutorial',
  dashboard: {
    welcomeBox: {
      title: 'Welcome to the Web3 Online Judge!',
      description: 'Web3OJ is the best blockchain online judge platform to improve your technical skills, learn new knowledge, and prepare for technical interviews.',
      tutorial: 'Tutorial',
    },
    service: {
      title: 'Service',
      StudyDescription: 'Solve problems and improve your rank.',
      CommunityDescription: 'Communicate with other people through the community.',
      DeveloperRecruitmentDescription: 'Find a job that suits you.',
    },
    LatestPost: 'Latest Post',
    Top3User: 'Top3 User',
  },
  Problem: {
    create: 'Get Problem Instance',
    creating: 'Creating...',
    title: 'Problem',
    difficulty: 'Difficulty',
    solver: 'Solver',
    submit: 'Submit',
    submitting: 'Submitting...',
    status: 'Status',
    board: 'Discuss',
    contractInstanceAddress : 'Problem Contract Instance Address',
    reference: 'Reference',
    Dialog: {
      load: 'Load Problem Contract Instance',
      loadDescription: 'A previously created problem contract instance exists. Are you sure you want to load?',
      Button: {
        delete: 'Delete',
        skip: 'Skip',
        load: 'Load',
      }
    },
    message: {
      connectMetamask: 'Please connect Metamask.',
      success: 'Success!',
      fail: 'Fail',
      completeSubmit: 'Submitted!',
      noSubmission: 'No submission Yet',
    },
    ContributeType: {
      CREATE: 'Creation',
      TRANSLATION: 'Translation',
      CORRECTION: 'Correction',
    }
  },
  CompetitionProblem: {
    title: 'Comeptition',
    difficulty: 'difficulty',
    solver: 'solver',
    submit: 'submit',
    CompetitionType: {
      COMMUNITY: 'Community',
    },
    TABLE_HEAD: {
      rank: 'Rank',
      nickname: 'Nickname',
      introduction: 'Introduction',
      score: 'Score',
      datetime: 'Datetime',
    },
    Tab: {
      description: 'Description',
      discuss: 'Discuss',
      rank: 'Leaderboard',
    },
  },
  Board: {
    post: 'Post',
    noPost: 'No Posts Yet',
    title: 'title',
    nickname: 'nickname',
    viewCount: 'view',
    commentCount: 'comment',
    displayTime: 'time',
    Post: {
      title: 'Post',
      subscribe: 'Subscribe',
      subscribed: 'Subscribed!',
      Setting: {
        title: 'Setting',
        delete: 'Delete',
        edit: 'Edit',
        cancel: 'Cancel',
      },
    },
    Comment: {
      comment: 'Comment',
      commentWrite: 'Write a comment',
      commentWriteButton: 'Write',
      Setting: {
        title: 'Setting',
        delete: 'Delete',
        edit: 'Edit',
        cancel: 'Cancel',
      }
    }
  },
  PostEditor: {
    post: 'Post',
    write: 'Write a post',
    edit: 'Edit post',
    submit: 'Submit',
    title: 'Title',
    dummyContent: '## Content\nYou can write content in **Markdown**. \n',
    message: {
      writeFail: 'Failed to write post. ',
      editFail: 'Failed to edit post. ',
    },
  },
  Rank: {
    title: 'Leaderboard',
    rank: 'rank',
    nickname: 'nickname',
    introduction: 'introduction',
    solved: 'solved',
  },
  User: {
    profile: 'Profile',
    solvedProblem: 'Solved Problem',
    createdProblem: 'Created Problem',
    group: 'Group',
    noSolvedProblem: 'No problem solved.',
    noCreatedProblem: 'No problem created.',
    noGroup: 'No group.',
  },
  UserCreate: {
    title: 'Join Web3OJ',
    modifyTitle: 'Edit Profile',
    wallet: 'Wallet',
    nickname: 'Nickname',
    introduction: 'Introduction',
    button: {
      signUp: 'Sign Up',
      update: 'Update',
    },
    message: {
      connectMetamask: 'Please connect Metamask',
      tooShortNickname: 'Nickname is too short. Please write at least 2 characters.',
      tooShortIntroduction: 'Introduction is too short. Please write at least 5 characters.',
      notSignUpUser: 'You are not a registered user. Please sign up!',
      successModify: 'Successfully modified!',
      failModify: 'Failed to modify.',
    },
  },
  MainBoard: {
    title: 'Forum',
    notice: 'Notice',
    free: 'General',
    question: 'Question',
    update: 'Update',
    DApp: 'DApp',
    NFT: 'NFT',
    DeFi: 'DeFi',
    promotion: 'Public Relations ',
    '공지사항': 'Notice',
    '자유 게시판': 'General',
    '질문 게시판': 'Question',
    '업데이트': 'Update',
    'DApp 개발 게시판': 'DApp',
    'NFT 게시판': 'NFT',
    'DeFi 게시판': 'DeFi',
    '홍보 게시판': 'Public Relations',
  },
  Group: {
    title: 'Group',
    id: 'id',
    name: 'group name',
    type: 'type',
    leader: 'leader',
    member: 'member',
    create: 'Create',
    createTitle: 'Create Group',
    groupType: 'Group Type',
    groupName: 'Group Name',
    groupCode: 'Secret Code',
    groupCodeDescription: 'Secret Code - A secret code is required to enter the Private Group.',
    cancel: 'Cancel',
    group: 'Group',
    join: 'Join',
    save: 'Save',
    delete: 'Group Delete',
    modifyGroupCodeDescription: 'Secret Code - Leave blank if you do not want to modify it.',
    Tab: {
      board: 'Group Forum',
      member: 'Member',
      joinRequest: 'Join Request'
    },
    noJoinRequest: 'No join request.',
    rankSuffix: '',
    joinRequestList: 'Join Request List',
    rejectList: 'Reject List',
    approve: 'Approve',
    reject: 'Reject',
    joinRequested: 'Join Requested',
    setting: 'Setting',
    message: {
      createSuccess: 'Group created successfully.',
      createFail: 'Group creation failed.',
      modifySuccess: 'Group modified successfully.',
      modifyFail: 'Group modification failed.',
      deleteSuccess: 'Group deleted successfully.',
      deleteFail: 'Group deletion failed.',
      joinRequestSuccess: 'Join request sent successfully.',
      joinRequestFail: 'Join request failed.',
      approveSuccess: 'Join request approved successfully.',
      approveFail: 'Join request approval failed.',
      rejectSuccess: 'Join request rejected successfully.',
      rejectFail: 'Join request rejection failed.',
      joinSuccess: 'Join request approved successfully.',
      joinFail: 'Join request approval failed.',
    },
    TABLE_HEAD: {
      rank: 'Rank',
      nickname: 'Nickname',
      address: 'Wallet Address',
      introduction: 'Introduction',
      solvedProblem: 'Solved Problem',
      action: 'Approve/Reject',
      approve: 'Approve',
    }
  },
  EASY: 'easy',
  MEDIUM: 'medium',
  HARD: 'hard',
};

export default en;
