// source: api/web3oj/proto/competition_problem.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var api_web3oj_proto_board_pb = require('../../../api/web3oj/proto/board_pb.js');
goog.object.extend(proto, api_web3oj_proto_board_pb);
var api_web3oj_proto_problem_pb = require('../../../api/web3oj/proto/problem_pb.js');
goog.object.extend(proto, api_web3oj_proto_problem_pb);
var api_web3oj_proto_user_pb = require('../../../api/web3oj/proto/user_pb.js');
goog.object.extend(proto, api_web3oj_proto_user_pb);
goog.exportSymbol('proto.web3oj.CompetitionProblem', null, global);
goog.exportSymbol('proto.web3oj.CompetitionProblemRank', null, global);
goog.exportSymbol('proto.web3oj.CompetitionProblemRankEntry', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.CompetitionProblem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web3oj.CompetitionProblem.repeatedFields_, null);
};
goog.inherits(proto.web3oj.CompetitionProblem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.CompetitionProblem.displayName = 'proto.web3oj.CompetitionProblem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.CompetitionProblemRank = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web3oj.CompetitionProblemRank.repeatedFields_, null);
};
goog.inherits(proto.web3oj.CompetitionProblemRank, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.CompetitionProblemRank.displayName = 'proto.web3oj.CompetitionProblemRank';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.CompetitionProblemRankEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.CompetitionProblemRankEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.CompetitionProblemRankEntry.displayName = 'proto.web3oj.CompetitionProblemRankEntry';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web3oj.CompetitionProblem.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.CompetitionProblem.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.CompetitionProblem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.CompetitionProblem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.CompetitionProblem.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionProblemId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contractAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    submitCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    submitStatus: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    competitionType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    reward: jspb.Message.getFieldWithDefault(msg, 7, ""),
    scorePolicy: jspb.Message.getFieldWithDefault(msg, 8, ""),
    deadline: jspb.Message.getFieldWithDefault(msg, 9, ""),
    boardsList: jspb.Message.toObjectList(msg.getBoardsList(),
    api_web3oj_proto_board_pb.Board.toObject, includeInstance),
    contributorsList: jspb.Message.toObjectList(msg.getContributorsList(),
    api_web3oj_proto_problem_pb.Contributor.toObject, includeInstance),
    content: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.CompetitionProblem}
 */
proto.web3oj.CompetitionProblem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.CompetitionProblem;
  return proto.web3oj.CompetitionProblem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.CompetitionProblem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.CompetitionProblem}
 */
proto.web3oj.CompetitionProblem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionProblemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubmitCount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubmitStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setReward(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setScorePolicy(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeadline(value);
      break;
    case 10:
      var value = new api_web3oj_proto_board_pb.Board;
      reader.readMessage(value,api_web3oj_proto_board_pb.Board.deserializeBinaryFromReader);
      msg.addBoards(value);
      break;
    case 11:
      var value = new api_web3oj_proto_problem_pb.Contributor;
      reader.readMessage(value,api_web3oj_proto_problem_pb.Contributor.deserializeBinaryFromReader);
      msg.addContributors(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.CompetitionProblem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.CompetitionProblem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.CompetitionProblem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.CompetitionProblem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionProblemId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getContractAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubmitCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSubmitStatus();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCompetitionType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReward();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getScorePolicy();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDeadline();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBoardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      api_web3oj_proto_board_pb.Board.serializeBinaryToWriter
    );
  }
  f = message.getContributorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      api_web3oj_proto_problem_pb.Contributor.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int32 competition_problem_id = 1;
 * @return {number}
 */
proto.web3oj.CompetitionProblem.prototype.getCompetitionProblemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.CompetitionProblem} returns this
 */
proto.web3oj.CompetitionProblem.prototype.setCompetitionProblemId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string contract_address = 2;
 * @return {string}
 */
proto.web3oj.CompetitionProblem.prototype.getContractAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.CompetitionProblem} returns this
 */
proto.web3oj.CompetitionProblem.prototype.setContractAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.web3oj.CompetitionProblem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.CompetitionProblem} returns this
 */
proto.web3oj.CompetitionProblem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 submit_count = 4;
 * @return {number}
 */
proto.web3oj.CompetitionProblem.prototype.getSubmitCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.CompetitionProblem} returns this
 */
proto.web3oj.CompetitionProblem.prototype.setSubmitCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool submit_status = 5;
 * @return {boolean}
 */
proto.web3oj.CompetitionProblem.prototype.getSubmitStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web3oj.CompetitionProblem} returns this
 */
proto.web3oj.CompetitionProblem.prototype.setSubmitStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string competition_type = 6;
 * @return {string}
 */
proto.web3oj.CompetitionProblem.prototype.getCompetitionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.CompetitionProblem} returns this
 */
proto.web3oj.CompetitionProblem.prototype.setCompetitionType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string reward = 7;
 * @return {string}
 */
proto.web3oj.CompetitionProblem.prototype.getReward = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.CompetitionProblem} returns this
 */
proto.web3oj.CompetitionProblem.prototype.setReward = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string score_policy = 8;
 * @return {string}
 */
proto.web3oj.CompetitionProblem.prototype.getScorePolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.CompetitionProblem} returns this
 */
proto.web3oj.CompetitionProblem.prototype.setScorePolicy = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string deadline = 9;
 * @return {string}
 */
proto.web3oj.CompetitionProblem.prototype.getDeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.CompetitionProblem} returns this
 */
proto.web3oj.CompetitionProblem.prototype.setDeadline = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated Board boards = 10;
 * @return {!Array<!proto.web3oj.Board>}
 */
proto.web3oj.CompetitionProblem.prototype.getBoardsList = function() {
  return /** @type{!Array<!proto.web3oj.Board>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_web3oj_proto_board_pb.Board, 10));
};


/**
 * @param {!Array<!proto.web3oj.Board>} value
 * @return {!proto.web3oj.CompetitionProblem} returns this
*/
proto.web3oj.CompetitionProblem.prototype.setBoardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.web3oj.Board=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.Board}
 */
proto.web3oj.CompetitionProblem.prototype.addBoards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.web3oj.Board, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.CompetitionProblem} returns this
 */
proto.web3oj.CompetitionProblem.prototype.clearBoardsList = function() {
  return this.setBoardsList([]);
};


/**
 * repeated Contributor contributors = 11;
 * @return {!Array<!proto.web3oj.Contributor>}
 */
proto.web3oj.CompetitionProblem.prototype.getContributorsList = function() {
  return /** @type{!Array<!proto.web3oj.Contributor>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_web3oj_proto_problem_pb.Contributor, 11));
};


/**
 * @param {!Array<!proto.web3oj.Contributor>} value
 * @return {!proto.web3oj.CompetitionProblem} returns this
*/
proto.web3oj.CompetitionProblem.prototype.setContributorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.web3oj.Contributor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.Contributor}
 */
proto.web3oj.CompetitionProblem.prototype.addContributors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.web3oj.Contributor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.CompetitionProblem} returns this
 */
proto.web3oj.CompetitionProblem.prototype.clearContributorsList = function() {
  return this.setContributorsList([]);
};


/**
 * optional string content = 12;
 * @return {string}
 */
proto.web3oj.CompetitionProblem.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.CompetitionProblem} returns this
 */
proto.web3oj.CompetitionProblem.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web3oj.CompetitionProblemRank.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.CompetitionProblemRank.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.CompetitionProblemRank.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.CompetitionProblemRank} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.CompetitionProblemRank.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.web3oj.CompetitionProblemRankEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.CompetitionProblemRank}
 */
proto.web3oj.CompetitionProblemRank.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.CompetitionProblemRank;
  return proto.web3oj.CompetitionProblemRank.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.CompetitionProblemRank} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.CompetitionProblemRank}
 */
proto.web3oj.CompetitionProblemRank.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.web3oj.CompetitionProblemRankEntry;
      reader.readMessage(value,proto.web3oj.CompetitionProblemRankEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.CompetitionProblemRank.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.CompetitionProblemRank.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.CompetitionProblemRank} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.CompetitionProblemRank.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.web3oj.CompetitionProblemRankEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CompetitionProblemRankEntry entries = 1;
 * @return {!Array<!proto.web3oj.CompetitionProblemRankEntry>}
 */
proto.web3oj.CompetitionProblemRank.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.web3oj.CompetitionProblemRankEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web3oj.CompetitionProblemRankEntry, 1));
};


/**
 * @param {!Array<!proto.web3oj.CompetitionProblemRankEntry>} value
 * @return {!proto.web3oj.CompetitionProblemRank} returns this
*/
proto.web3oj.CompetitionProblemRank.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.web3oj.CompetitionProblemRankEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.CompetitionProblemRankEntry}
 */
proto.web3oj.CompetitionProblemRank.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.web3oj.CompetitionProblemRankEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.CompetitionProblemRank} returns this
 */
proto.web3oj.CompetitionProblemRank.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.CompetitionProblemRankEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.CompetitionProblemRankEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.CompetitionProblemRankEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.CompetitionProblemRankEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    rank: jspb.Message.getFieldWithDefault(msg, 1, 0),
    user: (f = msg.getUser()) && api_web3oj_proto_user_pb.User.toObject(includeInstance, f),
    score: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayTime: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.CompetitionProblemRankEntry}
 */
proto.web3oj.CompetitionProblemRankEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.CompetitionProblemRankEntry;
  return proto.web3oj.CompetitionProblemRankEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.CompetitionProblemRankEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.CompetitionProblemRankEntry}
 */
proto.web3oj.CompetitionProblemRankEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    case 2:
      var value = new api_web3oj_proto_user_pb.User;
      reader.readMessage(value,api_web3oj_proto_user_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.CompetitionProblemRankEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.CompetitionProblemRankEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.CompetitionProblemRankEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.CompetitionProblemRankEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      api_web3oj_proto_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 rank = 1;
 * @return {number}
 */
proto.web3oj.CompetitionProblemRankEntry.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.CompetitionProblemRankEntry} returns this
 */
proto.web3oj.CompetitionProblemRankEntry.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional User User = 2;
 * @return {?proto.web3oj.User}
 */
proto.web3oj.CompetitionProblemRankEntry.prototype.getUser = function() {
  return /** @type{?proto.web3oj.User} */ (
    jspb.Message.getWrapperField(this, api_web3oj_proto_user_pb.User, 2));
};


/**
 * @param {?proto.web3oj.User|undefined} value
 * @return {!proto.web3oj.CompetitionProblemRankEntry} returns this
*/
proto.web3oj.CompetitionProblemRankEntry.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web3oj.CompetitionProblemRankEntry} returns this
 */
proto.web3oj.CompetitionProblemRankEntry.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web3oj.CompetitionProblemRankEntry.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string score = 3;
 * @return {string}
 */
proto.web3oj.CompetitionProblemRankEntry.prototype.getScore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.CompetitionProblemRankEntry} returns this
 */
proto.web3oj.CompetitionProblemRankEntry.prototype.setScore = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string display_time = 4;
 * @return {string}
 */
proto.web3oj.CompetitionProblemRankEntry.prototype.getDisplayTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.CompetitionProblemRankEntry} returns this
 */
proto.web3oj.CompetitionProblemRankEntry.prototype.setDisplayTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.web3oj);
