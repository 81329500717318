// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

// const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const ICONS = {
  home: getIcon('eva:pie-chart-2-fill'),
  problem: getIcon('ant-design:code-filled'),
  rank: getIcon('eva:bar-chart-2-fill'),
  group: getIcon('eva:people-fill'),
  board: getIcon('fa-regular:clipboard'),
  kakao: getIcon('ri:kakao-talk-fill'),
  line: getIcon('ri:line-fill'),
  discord: getIcon('simple-icons:discord'),
  job: getIcon('heroicons-outline:office-building'),
  offer: getIcon('mdi:offer'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  competition: getIcon('ant-design:trophy-outlined'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  {
    items: [
      { title: 'intro', path: PATH_DASHBOARD.app.home, icon: ICONS.home },
    ]
  },
  {
    subheader: 'Study',
    items: [
      { title: 'problem', path: PATH_DASHBOARD.app.problem, icon: ICONS.problem },
      { title: 'rank', path: PATH_DASHBOARD.app.rank, icon: ICONS.rank },
      { title: 'competition', path: PATH_DASHBOARD.app.competitionProblem, icon: ICONS.competition },
    ]
  },
  {
    subheader: 'Community',
    items: [
      { title: 'board', path: PATH_DASHBOARD.board.list, icon: ICONS.board },
      { title: 'group', path: PATH_DASHBOARD.app.group, icon: ICONS.group },
      { title: 'kakaochat', path: 'https://open.kakao.com/o/gfpkc1ce', icon: ICONS.kakao },
      { title: 'linechat', path: 'https://line.me/ti/g2/YyaVREnpaCko6BUCI8EuocqkMEGAbHAlJGmrKg', icon: ICONS.line },
      { title: 'discord', path: 'https://discord.gg/fmfCaYasbG', icon: ICONS.discord },
    ]
  },
  {
    subheader: 'DeveloperRecruitment',
    items: [
      { title: 'Jobposting', path: PATH_DASHBOARD.career.job, icon: ICONS.job },
      { title: 'Offer', path: PATH_DASHBOARD.career.offer, icon: ICONS.offer },
    ]
  },
];

export default navConfig;
