/**
 * @fileoverview gRPC-Web generated client stub for web3oj
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var api_web3oj_proto_competition_problem_pb = require('../../../api/web3oj/proto/competition_problem_pb.js')
const proto = {};
proto.web3oj = require('./competition_problem_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.web3oj.CompetitionProblemServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.web3oj.CompetitionProblemServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.ListCompetitionProblemRequest,
 *   !proto.web3oj.ListCompetitionProblemResponse>}
 */
const methodDescriptor_CompetitionProblemService_ListCompetitionProblem = new grpc.web.MethodDescriptor(
  '/web3oj.CompetitionProblemService/ListCompetitionProblem',
  grpc.web.MethodType.UNARY,
  proto.web3oj.ListCompetitionProblemRequest,
  proto.web3oj.ListCompetitionProblemResponse,
  /**
   * @param {!proto.web3oj.ListCompetitionProblemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.ListCompetitionProblemResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.ListCompetitionProblemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.ListCompetitionProblemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.ListCompetitionProblemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.CompetitionProblemServiceClient.prototype.listCompetitionProblem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.CompetitionProblemService/ListCompetitionProblem',
      request,
      metadata || {},
      methodDescriptor_CompetitionProblemService_ListCompetitionProblem,
      callback);
};


/**
 * @param {!proto.web3oj.ListCompetitionProblemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.ListCompetitionProblemResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.CompetitionProblemServicePromiseClient.prototype.listCompetitionProblem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.CompetitionProblemService/ListCompetitionProblem',
      request,
      metadata || {},
      methodDescriptor_CompetitionProblemService_ListCompetitionProblem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.GetCompetitionProblemRequest,
 *   !proto.web3oj.GetCompetitionProblemResponse>}
 */
const methodDescriptor_CompetitionProblemService_GetCompetitionProblem = new grpc.web.MethodDescriptor(
  '/web3oj.CompetitionProblemService/GetCompetitionProblem',
  grpc.web.MethodType.UNARY,
  proto.web3oj.GetCompetitionProblemRequest,
  proto.web3oj.GetCompetitionProblemResponse,
  /**
   * @param {!proto.web3oj.GetCompetitionProblemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.GetCompetitionProblemResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.GetCompetitionProblemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.GetCompetitionProblemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.GetCompetitionProblemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.CompetitionProblemServiceClient.prototype.getCompetitionProblem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.CompetitionProblemService/GetCompetitionProblem',
      request,
      metadata || {},
      methodDescriptor_CompetitionProblemService_GetCompetitionProblem,
      callback);
};


/**
 * @param {!proto.web3oj.GetCompetitionProblemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.GetCompetitionProblemResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.CompetitionProblemServicePromiseClient.prototype.getCompetitionProblem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.CompetitionProblemService/GetCompetitionProblem',
      request,
      metadata || {},
      methodDescriptor_CompetitionProblemService_GetCompetitionProblem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web3oj.GetCompetitionProblemRankRequest,
 *   !proto.web3oj.GetCompetitionProblemRankResponse>}
 */
const methodDescriptor_CompetitionProblemService_GetCompetitionProblemRank = new grpc.web.MethodDescriptor(
  '/web3oj.CompetitionProblemService/GetCompetitionProblemRank',
  grpc.web.MethodType.UNARY,
  proto.web3oj.GetCompetitionProblemRankRequest,
  proto.web3oj.GetCompetitionProblemRankResponse,
  /**
   * @param {!proto.web3oj.GetCompetitionProblemRankRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.web3oj.GetCompetitionProblemRankResponse.deserializeBinary
);


/**
 * @param {!proto.web3oj.GetCompetitionProblemRankRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.web3oj.GetCompetitionProblemRankResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.web3oj.GetCompetitionProblemRankResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.web3oj.CompetitionProblemServiceClient.prototype.getCompetitionProblemRank =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/web3oj.CompetitionProblemService/GetCompetitionProblemRank',
      request,
      metadata || {},
      methodDescriptor_CompetitionProblemService_GetCompetitionProblemRank,
      callback);
};


/**
 * @param {!proto.web3oj.GetCompetitionProblemRankRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.web3oj.GetCompetitionProblemRankResponse>}
 *     Promise that resolves to the response
 */
proto.web3oj.CompetitionProblemServicePromiseClient.prototype.getCompetitionProblemRank =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/web3oj.CompetitionProblemService/GetCompetitionProblemRank',
      request,
      metadata || {},
      methodDescriptor_CompetitionProblemService_GetCompetitionProblemRank);
};


module.exports = proto.web3oj;

