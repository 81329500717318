import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'app',
      element: (<DashboardLayout />),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <Home /> },
        { path: 'tutorial', element: <Tutorial/> },
        {
          path: 'problem',
          children: [
            { element: <Navigate to="/app/problem/list" replace />, index: true },
            { path: 'list', element: <ProblemList /> },
            { path: ':problemId', element: <Problem /> },
          ],
        },
        {
          path: 'competition',
          children: [
            { element: <Navigate to="/app/competition/problem/list" replace />, index: true },
            { path: 'problem/list', element: <CompetitionProblemList /> },
            { path: 'problem/:competitionProblemId', element: <CompetitionProblem /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/app/user/profile" replace />, index: true },
            { path: 'profile/:address', element: <Profile /> },
            { path: 'rank', element: <Rank /> },
            { path: 'list', element: <UserList /> },
            { path: 'create', element: <UserCreate /> },
            { path: 'update', element: <UserUpdate /> },
            // { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
            // { path: 'setting', element: <UserAccount /> },
          ],
        },
        {
          path: 'notification',
          children: [
            { element: <Navigate to="/app/notification/list" replace />, index: true },
            { path: 'list', element: <NotificationList /> },
          ],
        },
        {
          path: 'group',
          children: [
            { path: ':groupId', element: <Group /> },
            { path: 'list', element: <GroupList /> },
          ]
        },
        {
          path: 'career',
          children: [
            { path: 'job', element: <Job /> },
            { path: 'offer', element: <Offer /> },
          ]
        },
        {
          path: 'board',
          children: [
            { element: <Navigate to="/app/blog/posts" replace />, index: true },
            { path: 'list', element: <BoardList /> },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:postId', element: <Post /> },
            { path: 'post/new/:boardId', element: <PostEditor /> },
            { path: 'post/edit/:postId', element: <PostEditor /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        // {
        //   path: 'mail',
        //   children: [
        //     { element: <Navigate to="/app/mail/all" replace />, index: true },
        //     { path: 'label/:customLabel', element: <Mail /> },
        //     { path: 'label/:customLabel/:mailId', element: <Mail /> },
        //     { path: ':systemLabel', element: <Mail /> },
        //     { path: ':systemLabel/:mailId', element: <Mail /> },
        //   ],
        // },
        // {
        //   path: 'chat',
        //   children: [
        //     { element: <Chat />, index: true },
        //     { path: 'new', element: <Chat /> },
        //     { path: ':conversationKey', element: <Chat /> },
        //   ],
        // },
        // { path: 'calendar', element: <Calendar /> },
        // { path: 'kanban', element: <Kanban /> },
        // { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        // { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        // { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
// const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// const Register = Loadable(lazy(() => import('../pages/auth/Register')));
// const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// APP
const Home = Loadable(lazy(() => import('../pages/app/Home')));
const ProblemList = Loadable(lazy(() => import('../pages/app/ProblemList')));
const Problem = Loadable(lazy(() => import('../pages/app/Problem')));
const Rank = Loadable(lazy(() => import('../pages/app/Rank')));
const GroupList = Loadable(lazy(() => import('../pages/app/GroupList')));
const Group = Loadable(lazy(() => import('../pages/app/Group')));
const Profile = Loadable(lazy(() => import('../pages/app/Profile')));
const Job = Loadable(lazy(() => import('../pages/app/Job')));
const Offer = Loadable(lazy(() => import('../pages/app/Offer')));
const Tutorial = Loadable(lazy(() => import('../pages/app/Tutorial')));
const BoardList = Loadable(lazy(() => import('../pages/app/MainBoard')));
const NotificationList = Loadable(lazy(() => import('../pages/app/NotificationList')));

const CompetitionProblemList = Loadable(lazy(() => import('../pages/app/CompetitionProblemList')));
const CompetitionProblem = Loadable(lazy(() => import('../pages/app/CompetitionProblem')));

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const Post = Loadable(lazy(() => import('../pages/app/Post')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const PostEditor = Loadable(lazy(() => import('../pages/app/PostEditor')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/app/UserCreate')));
const UserUpdate = Loadable(lazy(() => import('../pages/app/UserUpdate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
