// material
import { Snackbar, Alert } from '@mui/material';

export default function SnackbarAlert(props) {
  const { snackbarOpen, setSnackbarOpen, message, serverity, ...other } = props;

  return (
    <div>
      <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={serverity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
