// source: api/web3oj/proto/board.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.web3oj.Board', null, global);
goog.exportSymbol('proto.web3oj.Board.Policy', null, global);
goog.exportSymbol('proto.web3oj.Emoji', null, global);
goog.exportSymbol('proto.web3oj.Emoji.EmojiType', null, global);
goog.exportSymbol('proto.web3oj.Post', null, global);
goog.exportSymbol('proto.web3oj.Post.Reference', null, global);
goog.exportSymbol('proto.web3oj.PostComment', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.Board = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.Board, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.Board.displayName = 'proto.web3oj.Board';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.Post = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web3oj.Post.repeatedFields_, null);
};
goog.inherits(proto.web3oj.Post, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.Post.displayName = 'proto.web3oj.Post';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.Post.Reference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.Post.Reference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.Post.Reference.displayName = 'proto.web3oj.Post.Reference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.PostComment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web3oj.PostComment.repeatedFields_, null);
};
goog.inherits(proto.web3oj.PostComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.PostComment.displayName = 'proto.web3oj.PostComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.Emoji = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.Emoji, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.Emoji.displayName = 'proto.web3oj.Emoji';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.Board.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.Board.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.Board} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.Board.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    policy: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.Board}
 */
proto.web3oj.Board.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.Board;
  return proto.web3oj.Board.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.Board} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.Board}
 */
proto.web3oj.Board.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.web3oj.Board.Policy} */ (reader.readEnum());
      msg.setPolicy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.Board.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.Board.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.Board} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.Board.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPolicy();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.web3oj.Board.Policy = {
  PUBLIC: 0,
  PRIVATE: 1,
  PUBLIC_ONLY_VIEW: 2
};

/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web3oj.Board.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.Board} returns this
 */
proto.web3oj.Board.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web3oj.Board.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.Board} returns this
 */
proto.web3oj.Board.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.web3oj.Board.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.Board} returns this
 */
proto.web3oj.Board.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Policy policy = 4;
 * @return {!proto.web3oj.Board.Policy}
 */
proto.web3oj.Board.prototype.getPolicy = function() {
  return /** @type {!proto.web3oj.Board.Policy} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.web3oj.Board.Policy} value
 * @return {!proto.web3oj.Board} returns this
 */
proto.web3oj.Board.prototype.setPolicy = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web3oj.Post.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.Post.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.Post.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.Post} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.Post.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    content: jspb.Message.getFieldWithDefault(msg, 4, ""),
    viewCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    emojiCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    commentCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    displayTime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    shareId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isBookmark: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isNotification: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    emojisList: jspb.Message.toObjectList(msg.getEmojisList(),
    proto.web3oj.Emoji.toObject, includeInstance),
    nickName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    reference: (f = msg.getReference()) && proto.web3oj.Post.Reference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.Post}
 */
proto.web3oj.Post.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.Post;
  return proto.web3oj.Post.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.Post} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.Post}
 */
proto.web3oj.Post.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setViewCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEmojiCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCommentCount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayTime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setShareId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBookmark(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNotification(value);
      break;
    case 12:
      var value = new proto.web3oj.Emoji;
      reader.readMessage(value,proto.web3oj.Emoji.deserializeBinaryFromReader);
      msg.addEmojis(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickName(value);
      break;
    case 14:
      var value = new proto.web3oj.Post.Reference;
      reader.readMessage(value,proto.web3oj.Post.Reference.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.Post.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.Post.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.Post} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.Post.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getViewCount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getEmojiCount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCommentCount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDisplayTime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getShareId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsBookmark();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsNotification();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getEmojisList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.web3oj.Emoji.serializeBinaryToWriter
    );
  }
  f = message.getNickName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.web3oj.Post.Reference.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.Post.Reference.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.Post.Reference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.Post.Reference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.Post.Reference.toObject = function(includeInstance, msg) {
  var f, obj = {
    refKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    refValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    boardId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.Post.Reference}
 */
proto.web3oj.Post.Reference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.Post.Reference;
  return proto.web3oj.Post.Reference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.Post.Reference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.Post.Reference}
 */
proto.web3oj.Post.Reference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBoardId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.Post.Reference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.Post.Reference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.Post.Reference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.Post.Reference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRefKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRefValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBoardId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string ref_key = 1;
 * @return {string}
 */
proto.web3oj.Post.Reference.prototype.getRefKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.Post.Reference} returns this
 */
proto.web3oj.Post.Reference.prototype.setRefKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ref_value = 2;
 * @return {string}
 */
proto.web3oj.Post.Reference.prototype.getRefValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.Post.Reference} returns this
 */
proto.web3oj.Post.Reference.prototype.setRefValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 board_id = 3;
 * @return {number}
 */
proto.web3oj.Post.Reference.prototype.getBoardId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.Post.Reference} returns this
 */
proto.web3oj.Post.Reference.prototype.setBoardId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.web3oj.Post.Reference.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.Post.Reference} returns this
 */
proto.web3oj.Post.Reference.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.web3oj.Post.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string user_address = 2;
 * @return {string}
 */
proto.web3oj.Post.prototype.getUserAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.setUserAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.web3oj.Post.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string content = 4;
 * @return {string}
 */
proto.web3oj.Post.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 view_count = 5;
 * @return {number}
 */
proto.web3oj.Post.prototype.getViewCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.setViewCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 emoji_count = 6;
 * @return {number}
 */
proto.web3oj.Post.prototype.getEmojiCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.setEmojiCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 comment_count = 7;
 * @return {number}
 */
proto.web3oj.Post.prototype.getCommentCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.setCommentCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string display_time = 8;
 * @return {string}
 */
proto.web3oj.Post.prototype.getDisplayTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.setDisplayTime = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string share_id = 9;
 * @return {string}
 */
proto.web3oj.Post.prototype.getShareId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.setShareId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool is_bookmark = 10;
 * @return {boolean}
 */
proto.web3oj.Post.prototype.getIsBookmark = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.setIsBookmark = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_notification = 11;
 * @return {boolean}
 */
proto.web3oj.Post.prototype.getIsNotification = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.setIsNotification = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated Emoji emojis = 12;
 * @return {!Array<!proto.web3oj.Emoji>}
 */
proto.web3oj.Post.prototype.getEmojisList = function() {
  return /** @type{!Array<!proto.web3oj.Emoji>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web3oj.Emoji, 12));
};


/**
 * @param {!Array<!proto.web3oj.Emoji>} value
 * @return {!proto.web3oj.Post} returns this
*/
proto.web3oj.Post.prototype.setEmojisList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.web3oj.Emoji=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.Emoji}
 */
proto.web3oj.Post.prototype.addEmojis = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.web3oj.Emoji, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.clearEmojisList = function() {
  return this.setEmojisList([]);
};


/**
 * optional string nick_name = 13;
 * @return {string}
 */
proto.web3oj.Post.prototype.getNickName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.setNickName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional Reference reference = 14;
 * @return {?proto.web3oj.Post.Reference}
 */
proto.web3oj.Post.prototype.getReference = function() {
  return /** @type{?proto.web3oj.Post.Reference} */ (
    jspb.Message.getWrapperField(this, proto.web3oj.Post.Reference, 14));
};


/**
 * @param {?proto.web3oj.Post.Reference|undefined} value
 * @return {!proto.web3oj.Post} returns this
*/
proto.web3oj.Post.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web3oj.Post} returns this
 */
proto.web3oj.Post.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web3oj.Post.prototype.hasReference = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web3oj.PostComment.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.PostComment.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.PostComment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.PostComment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.PostComment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    content: jspb.Message.getFieldWithDefault(msg, 4, ""),
    displayTime: jspb.Message.getFieldWithDefault(msg, 5, ""),
    emojiList: jspb.Message.toObjectList(msg.getEmojiList(),
    proto.web3oj.Emoji.toObject, includeInstance),
    commentCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    nickName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.PostComment}
 */
proto.web3oj.PostComment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.PostComment;
  return proto.web3oj.PostComment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.PostComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.PostComment}
 */
proto.web3oj.PostComment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayTime(value);
      break;
    case 6:
      var value = new proto.web3oj.Emoji;
      reader.readMessage(value,proto.web3oj.Emoji.deserializeBinaryFromReader);
      msg.addEmoji(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCommentCount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.PostComment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.PostComment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.PostComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.PostComment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDisplayTime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmojiList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.web3oj.Emoji.serializeBinaryToWriter
    );
  }
  f = message.getCommentCount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getNickName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.web3oj.PostComment.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.PostComment} returns this
 */
proto.web3oj.PostComment.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string user_address = 2;
 * @return {string}
 */
proto.web3oj.PostComment.prototype.getUserAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.PostComment} returns this
 */
proto.web3oj.PostComment.prototype.setUserAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 parent_id = 3;
 * @return {number}
 */
proto.web3oj.PostComment.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.PostComment} returns this
 */
proto.web3oj.PostComment.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string content = 4;
 * @return {string}
 */
proto.web3oj.PostComment.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.PostComment} returns this
 */
proto.web3oj.PostComment.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string display_time = 5;
 * @return {string}
 */
proto.web3oj.PostComment.prototype.getDisplayTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.PostComment} returns this
 */
proto.web3oj.PostComment.prototype.setDisplayTime = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Emoji emoji = 6;
 * @return {!Array<!proto.web3oj.Emoji>}
 */
proto.web3oj.PostComment.prototype.getEmojiList = function() {
  return /** @type{!Array<!proto.web3oj.Emoji>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web3oj.Emoji, 6));
};


/**
 * @param {!Array<!proto.web3oj.Emoji>} value
 * @return {!proto.web3oj.PostComment} returns this
*/
proto.web3oj.PostComment.prototype.setEmojiList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.web3oj.Emoji=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.Emoji}
 */
proto.web3oj.PostComment.prototype.addEmoji = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.web3oj.Emoji, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.PostComment} returns this
 */
proto.web3oj.PostComment.prototype.clearEmojiList = function() {
  return this.setEmojiList([]);
};


/**
 * optional int64 comment_count = 7;
 * @return {number}
 */
proto.web3oj.PostComment.prototype.getCommentCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.PostComment} returns this
 */
proto.web3oj.PostComment.prototype.setCommentCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string nick_name = 8;
 * @return {string}
 */
proto.web3oj.PostComment.prototype.getNickName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.PostComment} returns this
 */
proto.web3oj.PostComment.prototype.setNickName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.Emoji.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.Emoji.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.Emoji} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.Emoji.toObject = function(includeInstance, msg) {
  var f, obj = {
    emoji: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    usage: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    emojitype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.Emoji}
 */
proto.web3oj.Emoji.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.Emoji;
  return proto.web3oj.Emoji.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.Emoji} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.Emoji}
 */
proto.web3oj.Emoji.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmoji(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsage(value);
      break;
    case 4:
      var value = /** @type {!proto.web3oj.Emoji.EmojiType} */ (reader.readEnum());
      msg.setEmojitype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.Emoji.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.Emoji.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.Emoji} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.Emoji.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmoji();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUsage();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getEmojitype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.web3oj.Emoji.EmojiType = {
  DEFAULT: 0,
  URL_IMAGE: 1
};

/**
 * optional string emoji = 1;
 * @return {string}
 */
proto.web3oj.Emoji.prototype.getEmoji = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.Emoji} returns this
 */
proto.web3oj.Emoji.prototype.setEmoji = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.web3oj.Emoji.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.Emoji} returns this
 */
proto.web3oj.Emoji.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool usage = 3;
 * @return {boolean}
 */
proto.web3oj.Emoji.prototype.getUsage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.web3oj.Emoji} returns this
 */
proto.web3oj.Emoji.prototype.setUsage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional EmojiType emojiType = 4;
 * @return {!proto.web3oj.Emoji.EmojiType}
 */
proto.web3oj.Emoji.prototype.getEmojitype = function() {
  return /** @type {!proto.web3oj.Emoji.EmojiType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.web3oj.Emoji.EmojiType} value
 * @return {!proto.web3oj.Emoji} returns this
 */
proto.web3oj.Emoji.prototype.setEmojitype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


goog.object.extend(exports, proto.web3oj);
