import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  commentNonce: 0
};

const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    // Set Address
    updateCommentNonce(state, action) {
      state.commentNonce += 1;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { updateCommentNonce } = slice.actions;

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
