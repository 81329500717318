// source: api/web3oj/proto/user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var api_web3oj_proto_problem_pb = require('../../../api/web3oj/proto/problem_pb.js');
goog.object.extend(proto, api_web3oj_proto_problem_pb);
goog.exportSymbol('proto.web3oj.User', null, global);
goog.exportSymbol('proto.web3oj.User.GroupInfo', null, global);
goog.exportSymbol('proto.web3oj.User.Social', null, global);
goog.exportSymbol('proto.web3oj.UserProblemHeatmap', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.web3oj.User.repeatedFields_, null);
};
goog.inherits(proto.web3oj.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.User.displayName = 'proto.web3oj.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.User.GroupInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.User.GroupInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.User.GroupInfo.displayName = 'proto.web3oj.User.GroupInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.User.Social = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.User.Social, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.User.Social.displayName = 'proto.web3oj.User.Social';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.web3oj.UserProblemHeatmap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.web3oj.UserProblemHeatmap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.web3oj.UserProblemHeatmap.displayName = 'proto.web3oj.UserProblemHeatmap';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.web3oj.User.repeatedFields_ = [6,7,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.User.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bio: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profileImg: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rank: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numProblemsPassed: jspb.Message.getFieldWithDefault(msg, 5, 0),
    problemsPassedList: jspb.Message.toObjectList(msg.getProblemsPassedList(),
    api_web3oj_proto_problem_pb.Problem.toObject, includeInstance),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.web3oj.User.GroupInfo.toObject, includeInstance),
    nickName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    social: (f = msg.getSocial()) && proto.web3oj.User.Social.toObject(includeInstance, f),
    problemsCreatedList: jspb.Message.toObjectList(msg.getProblemsCreatedList(),
    api_web3oj_proto_problem_pb.Problem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.User}
 */
proto.web3oj.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.User;
  return proto.web3oj.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.User}
 */
proto.web3oj.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBio(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImg(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumProblemsPassed(value);
      break;
    case 6:
      var value = new api_web3oj_proto_problem_pb.Problem;
      reader.readMessage(value,api_web3oj_proto_problem_pb.Problem.deserializeBinaryFromReader);
      msg.addProblemsPassed(value);
      break;
    case 7:
      var value = new proto.web3oj.User.GroupInfo;
      reader.readMessage(value,proto.web3oj.User.GroupInfo.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickName(value);
      break;
    case 9:
      var value = new proto.web3oj.User.Social;
      reader.readMessage(value,proto.web3oj.User.Social.deserializeBinaryFromReader);
      msg.setSocial(value);
      break;
    case 10:
      var value = new api_web3oj_proto_problem_pb.Problem;
      reader.readMessage(value,api_web3oj_proto_problem_pb.Problem.deserializeBinaryFromReader);
      msg.addProblemsCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBio();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfileImg();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getNumProblemsPassed();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getProblemsPassedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      api_web3oj_proto_problem_pb.Problem.serializeBinaryToWriter
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.web3oj.User.GroupInfo.serializeBinaryToWriter
    );
  }
  f = message.getNickName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSocial();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.web3oj.User.Social.serializeBinaryToWriter
    );
  }
  f = message.getProblemsCreatedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      api_web3oj_proto_problem_pb.Problem.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.User.GroupInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.User.GroupInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.User.GroupInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.User.GroupInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.User.GroupInfo}
 */
proto.web3oj.User.GroupInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.User.GroupInfo;
  return proto.web3oj.User.GroupInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.User.GroupInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.User.GroupInfo}
 */
proto.web3oj.User.GroupInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.User.GroupInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.User.GroupInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.User.GroupInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.User.GroupInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.web3oj.User.GroupInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.User.GroupInfo} returns this
 */
proto.web3oj.User.GroupInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.web3oj.User.GroupInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.User.GroupInfo} returns this
 */
proto.web3oj.User.GroupInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.User.Social.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.User.Social.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.User.Social} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.User.Social.toObject = function(includeInstance, msg) {
  var f, obj = {
    other: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linkedIn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    github: jspb.Message.getFieldWithDefault(msg, 3, ""),
    twitter: jspb.Message.getFieldWithDefault(msg, 4, ""),
    instagram: jspb.Message.getFieldWithDefault(msg, 5, ""),
    facebook: jspb.Message.getFieldWithDefault(msg, 6, ""),
    brunch: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.User.Social}
 */
proto.web3oj.User.Social.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.User.Social;
  return proto.web3oj.User.Social.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.User.Social} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.User.Social}
 */
proto.web3oj.User.Social.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOther(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkedIn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGithub(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTwitter(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstagram(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacebook(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrunch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.User.Social.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.User.Social.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.User.Social} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.User.Social.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOther();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinkedIn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGithub();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTwitter();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInstagram();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFacebook();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBrunch();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string other = 1;
 * @return {string}
 */
proto.web3oj.User.Social.prototype.getOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.User.Social} returns this
 */
proto.web3oj.User.Social.prototype.setOther = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string linked_in = 2;
 * @return {string}
 */
proto.web3oj.User.Social.prototype.getLinkedIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.User.Social} returns this
 */
proto.web3oj.User.Social.prototype.setLinkedIn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string github = 3;
 * @return {string}
 */
proto.web3oj.User.Social.prototype.getGithub = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.User.Social} returns this
 */
proto.web3oj.User.Social.prototype.setGithub = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string twitter = 4;
 * @return {string}
 */
proto.web3oj.User.Social.prototype.getTwitter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.User.Social} returns this
 */
proto.web3oj.User.Social.prototype.setTwitter = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string instagram = 5;
 * @return {string}
 */
proto.web3oj.User.Social.prototype.getInstagram = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.User.Social} returns this
 */
proto.web3oj.User.Social.prototype.setInstagram = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string facebook = 6;
 * @return {string}
 */
proto.web3oj.User.Social.prototype.getFacebook = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.User.Social} returns this
 */
proto.web3oj.User.Social.prototype.setFacebook = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string brunch = 7;
 * @return {string}
 */
proto.web3oj.User.Social.prototype.getBrunch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.User.Social} returns this
 */
proto.web3oj.User.Social.prototype.setBrunch = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.web3oj.User.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.User} returns this
 */
proto.web3oj.User.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bio = 2;
 * @return {string}
 */
proto.web3oj.User.prototype.getBio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.User} returns this
 */
proto.web3oj.User.prototype.setBio = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_img = 3;
 * @return {string}
 */
proto.web3oj.User.prototype.getProfileImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.User} returns this
 */
proto.web3oj.User.prototype.setProfileImg = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 rank = 4;
 * @return {number}
 */
proto.web3oj.User.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.User} returns this
 */
proto.web3oj.User.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 num_problems_passed = 5;
 * @return {number}
 */
proto.web3oj.User.prototype.getNumProblemsPassed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.User} returns this
 */
proto.web3oj.User.prototype.setNumProblemsPassed = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated Problem problems_passed = 6;
 * @return {!Array<!proto.web3oj.Problem>}
 */
proto.web3oj.User.prototype.getProblemsPassedList = function() {
  return /** @type{!Array<!proto.web3oj.Problem>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_web3oj_proto_problem_pb.Problem, 6));
};


/**
 * @param {!Array<!proto.web3oj.Problem>} value
 * @return {!proto.web3oj.User} returns this
*/
proto.web3oj.User.prototype.setProblemsPassedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.web3oj.Problem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.Problem}
 */
proto.web3oj.User.prototype.addProblemsPassed = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.web3oj.Problem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.User} returns this
 */
proto.web3oj.User.prototype.clearProblemsPassedList = function() {
  return this.setProblemsPassedList([]);
};


/**
 * repeated GroupInfo groups = 7;
 * @return {!Array<!proto.web3oj.User.GroupInfo>}
 */
proto.web3oj.User.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.web3oj.User.GroupInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.web3oj.User.GroupInfo, 7));
};


/**
 * @param {!Array<!proto.web3oj.User.GroupInfo>} value
 * @return {!proto.web3oj.User} returns this
*/
proto.web3oj.User.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.web3oj.User.GroupInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.User.GroupInfo}
 */
proto.web3oj.User.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.web3oj.User.GroupInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.User} returns this
 */
proto.web3oj.User.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional string nick_name = 8;
 * @return {string}
 */
proto.web3oj.User.prototype.getNickName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.User} returns this
 */
proto.web3oj.User.prototype.setNickName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Social social = 9;
 * @return {?proto.web3oj.User.Social}
 */
proto.web3oj.User.prototype.getSocial = function() {
  return /** @type{?proto.web3oj.User.Social} */ (
    jspb.Message.getWrapperField(this, proto.web3oj.User.Social, 9));
};


/**
 * @param {?proto.web3oj.User.Social|undefined} value
 * @return {!proto.web3oj.User} returns this
*/
proto.web3oj.User.prototype.setSocial = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.web3oj.User} returns this
 */
proto.web3oj.User.prototype.clearSocial = function() {
  return this.setSocial(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.web3oj.User.prototype.hasSocial = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated Problem problems_created = 10;
 * @return {!Array<!proto.web3oj.Problem>}
 */
proto.web3oj.User.prototype.getProblemsCreatedList = function() {
  return /** @type{!Array<!proto.web3oj.Problem>} */ (
    jspb.Message.getRepeatedWrapperField(this, api_web3oj_proto_problem_pb.Problem, 10));
};


/**
 * @param {!Array<!proto.web3oj.Problem>} value
 * @return {!proto.web3oj.User} returns this
*/
proto.web3oj.User.prototype.setProblemsCreatedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.web3oj.Problem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.web3oj.Problem}
 */
proto.web3oj.User.prototype.addProblemsCreated = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.web3oj.Problem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.web3oj.User} returns this
 */
proto.web3oj.User.prototype.clearProblemsCreatedList = function() {
  return this.setProblemsCreatedList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.web3oj.UserProblemHeatmap.prototype.toObject = function(opt_includeInstance) {
  return proto.web3oj.UserProblemHeatmap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.web3oj.UserProblemHeatmap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.UserProblemHeatmap.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.web3oj.UserProblemHeatmap}
 */
proto.web3oj.UserProblemHeatmap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.web3oj.UserProblemHeatmap;
  return proto.web3oj.UserProblemHeatmap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.web3oj.UserProblemHeatmap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.web3oj.UserProblemHeatmap}
 */
proto.web3oj.UserProblemHeatmap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.web3oj.UserProblemHeatmap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.web3oj.UserProblemHeatmap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.web3oj.UserProblemHeatmap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.web3oj.UserProblemHeatmap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.web3oj.UserProblemHeatmap.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.web3oj.UserProblemHeatmap} returns this
 */
proto.web3oj.UserProblemHeatmap.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.web3oj.UserProblemHeatmap.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.web3oj.UserProblemHeatmap} returns this
 */
proto.web3oj.UserProblemHeatmap.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.web3oj);
