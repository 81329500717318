import { GroupServiceClient } from './proto/group_service_grpc_web_pb';
import { Web3OJServiceClient } from './proto/web3ojservice_grpc_web_pb';
import { BoardServiceClient } from './proto/board_service_grpc_web_pb';
import { NotificationServiceClient } from './proto/notification_service_grpc_web_pb';
import { CompetitionProblemServiceClient } from './proto/competition_problem_service_grpc_web_pb';

const host = process.env.REACT_APP_WEB3OJ_SRV;

const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});

const web3OJService = new Web3OJServiceClient(host, null, null);
const groupService = new GroupServiceClient(host, null, null);
const boardService = new BoardServiceClient(host, null, null);
const notificationService = new NotificationServiceClient(host, null, null);
const competitionProblemService = new CompetitionProblemServiceClient(host, null, null);

const Web3OJClient = { web3OJService, groupService, boardService, notificationService, competitionProblemService };

enableDevTools(Web3OJClient);

export default Web3OJClient;
